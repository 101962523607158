export default {
   CREATE_VIDEO_MEETING: "create_meeting",
   MEETING_CHAT_SEND_MESSAGE: 'message',
   MEETING_CHAT_GET_MESSAGE: "createMessage",
   DELETE_USER_FROM_MEETING: 'delete_user_from_meeting',
   CHAT_PRIVATE_JOIN_ROOM: "private_chat_join",
   CHAT_CLOSE: 'close_room',
   CHAT_CREATE: 'create_room',
   CHAT_PRIVATE_SEND_MESSAGE: 'send_message',
   CHAT_PRIVATE_GET_MESSAGE: 'client-chat',
   MEETING_CREATE: 'create_meeting',
   PRIVATE_MEETING_CREATE: 'create-room',
   MAKE_PRIVATE_CALL: 'call-one-user',
   JOIN_CONNECTED: 'join_connected',
   APPROVE_OR_CANCEL_PRIVATE_CALL: 'approve_or_cancel_call',
   JOIN_MEETING_ROOM: 'join-room',
   MEETING_USER_DISCONNECT: 'user_disconnected',
   MEETING_CONNECTION_REQUEST: 'connection-request',
   CHAT_ROOM_INFO: 'room-info',
   CHAT_ROOM_INFO_NEW_TAB: "CHAT_ROOM_INFO_NEW_TAB",
   CHAT_ROOM_INFO_ADMIN: 'room-info-admin',
   USERS_ONLINE_LIST: 'users_online_list',
   CHAT_UNVIEWED_ROOMS: 'unviewed_message',
   CHAT_VIEWED_ROOMS: 'viewed_message',
   CHAT_REFRESH_ROOMS: 'chat_refresh',
   PRIVATE_CALL_ANSWER_APPROVE: 'answer_call_approve',
   PRIVATE_CALL_ANSWER_CANCEL: 'answer_call_cancel',
   MEETINGS_ME_INVITED: 'get_me_invited_meetings',
   PRIVATE_CALL_WAIT: 'calling',
   MEETING_NEW_USER_CONNECT: 'new_user_connected',
   MEETING_REMOVE_DISCONNECTED_USER_VIDEO:"remove-user-stream",
   USER_EXIT_FROM_MEETING: "user-disconnected-all",
   PARTICIPANTS_COUNT: "participants",
   MEETING_FINISHED: "meeting_finished",
   MEETING_STARTED: "meeting_begin",
   PRIVATE_CHAT_JOIN: "private-chat-join-room",
   SEND_MESSAGE_ALL: "trigger-selected-users",
   APPROVE_OR_DECLINE_MEETING: "approve_or_decline_meeting",
   CHAT_PRIVATE_SEND_MESSAGE_ADMIN: 'send_message_admin',
   PAGE_REFRESH: "page_refresh",
   SHUT_DOWN_CALL: 'shut_down_call',
   CHECK_USER_BUSY: "check_user_busy",
   CHECK_USER_BUSY_ANSWER: "check_user_busy_answer",
   SEND_MESSAGE_TO_ADMIN: "SEND_MESSAGE_TO_ADMIN",
   GET_MY_MESSAGE_MYSELF: "GET_MY_MESSAGE_MYSELF",
   GET_MESSAGE_FROM_ADMIN: "GET_MESSAGE_FROM_ADMIN",
   USER_CONNECTED_MEETING_ROOM: "user_connected_meeting"


}