import React, {useContext, useEffect} from 'react';
import {Menu} from "@headlessui/react";
import {useStore} from "../../../store";
import  {observer} from "mobx-react";
import { langContext } from '../../../App';
import { langPinCadeList } from './pinCodeListTranslate';

const PinCodeList = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langPinCadeList[lang]

    const {adminStore} = useStore()
    useEffect(() => {
        adminStore.getAllPinCodes()
    }, [])

    return (
        <div className="flex flex-col border-r border-slate-300 overflow-hidden  basis-full">
            <div className='border-b p-3'>
                <span className='font-bold'>{showBySelected.tr_pinCodeList}</span>
            </div>

            <div className='overflow-y-scroll'>
                <table className="border-collapse border border-slate-400 w-full">
                    <thead>
                    <tr>
                        <th className="border border-slate-300 ">{showBySelected.tr_name}</th>
                        <th className="border border-slate-300 ">{showBySelected.tr_numberOfUsers}</th>
                        <th className="border border-slate-300 ">{showBySelected.tr_userLimit}</th>
                        <th className="border border-slate-300 ">{showBySelected.tr_addressee}</th>
                    </tr>
                    </thead>
                    {/*<InfiniteScroll */}
                    {/*      dataLength={50}*/}
                    {/*      next={VideoRoom.getAllVideoMeetings()}*/}
                    {/*      hasMore={true}*/}
                    {/*     // loader={}*/}
                    {/*>*/}
                    <tbody className=''>
                    {adminStore.pin_codes.map((room, idx) => (
                        <Menu key={idx} as="tr"
                              className={"relative p-1 border text-gray-500 hover:text-gray-800 hover:bg-gray-100 hover:cursor-pointer "}>
                            <Menu.Button as={'td'} className='border border-slate-300 '>{room.code}</Menu.Button>
                            <Menu.Button as={'td'}
                                         className='border border-slate-300 '>{room.used_count}</Menu.Button>
                            <Menu.Button as={'td'}
                                         className='border border-slate-300 '>{room.used_limit}</Menu.Button>
                            <Menu.Button as={'td'}
                                         className='border border-slate-300 '>{room.used_username}</Menu.Button>

                        </Menu>
                    ))}
                    </tbody>
                    {/*</InfiniteScroll>*/}
                </table>
            </div>
        </div>
    );
});

export default PinCodeList;