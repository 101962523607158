import React, {useContext, useState} from 'react';
import DateTimePicker from "react-datetime-picker";
import { langContext } from '../../App';
import {useStore} from "../../store";
import { langDeleteByDate } from './deleteByDateTranslate';
import "./delete.css"

const DeleteByDate = ({approveHandler, cancelHandler,reason_need = true}) => {
    const lang = useContext(langContext);
    let showBySelected = langDeleteByDate[lang]

    const [reasonMessage, setReasonMessage] = useState('')


    

    return (

        <div className="dlt-main-div">
            <div>
                <p className='dlt-header'>Ջնջել</p>
                <p className="dlt-question">Դուք վստահ ե՞ք, որ ուզում եք ջնջել կոնֆերանսը ցանկից</p>
            </div>

            <div
                className="dlt-btns">
                <button
                        onClick={() => cancelHandler()}
                        className="dlt-reject">{showBySelected.tr_refuse}</button>
                <button
                    onClick={() => approveHandler(reasonMessage)}
                        className="dlt-accept">{showBySelected.tr_confirm}
                </button>
            </div>

        </div>
    );
};

export default DeleteByDate;