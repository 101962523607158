import React, {useContext, useEffect, useState} from 'react';
import LoadingSpinner from "../../../components/Loading";
import User from "../../../container/Admin/User/User"
import {useStore} from "../../../store";
import {observer} from "mobx-react-lite";
import SingleChat from "../../../components/PrivateChat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage} from "@fortawesome/free-solid-svg-icons";
import {socket} from "../../../const/socket";
import SOCKET from "../../../const/socketNames";
import { langContext } from '../../../App';
import { langSideNav } from './sideNavTranslate';

const SideNav = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langSideNav[lang]
    
    const [loading, setLoading] = useState(true)

    const {adminStore, chatStore,usersStore,userStore} = useStore()
    useEffect(()=> {
        adminStore.getUsers().then(res => setLoading(false))
        usersStore.getUnreadMessages()
        // usersStore.getUsers()
    }, [])

    const getUsersBySearch = (e) =>{
        adminStore.getUsers(e.target.value)
    }

    useEffect(() => {
        socket.on(SOCKET.CHAT_UNVIEWED_ROOMS, (data) => {
            usersStore.setUnviewedChats(data.username)
        })
        // socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
        //
        // })
        socket.on(SOCKET.USERS_ONLINE_LIST, (users) => usersStore.setOnlineUsersList(users))

        return () => {
            socket.off(SOCKET.CHAT_UNVIEWED_ROOMS)
            socket.off(SOCKET.CHAT_REFRESH_ROOMS)
            socket.off(SOCKET.USERS_ONLINE_LIST)
        }
    }, [])

    const users = adminStore.usersList.map(el => (<User key={el._id} user={el}/>) )
    return (
        <div className="flex flex-col  min-w-fit border-gray-200 w-72  border-r">
            <div className='border-gray-200 border-b  py-1 flex justify-center  w-full'>
                <span className='font-bold '>{showBySelected.tr_users}</span>
            </div>
            <ul className="overflow-y-scroll h-full">
                {loading ?<div className="flex justify-center items-center mt-10"><LoadingSpinner /></div> : users }

                {/*<FontAwesomeIcon icon={faMessage} className="fa-solid fa-messages" />*/}
            </ul>
            <div className=" border-blue-500 rounded-3xl my-3 relative">
                <input onChange={(e) => getUsersBySearch(e)}
                       className="bg-gray-200 border-none m-0 p-2 pl-8 text-md rounded-2xl" type="search"
                       placeholder={showBySelected.tr_search} />
            </div>
            {chatStore.user && <SingleChat />}
        </div>
    );
});

export default SideNav;