import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useStore} from "../../../store";
import {observer} from "mobx-react-lite";
import {socket} from "../../../const/socket";
import SOCKET from "../../../const/socketNames";
import Toggle from "../../../components/Toggle/Toggle";
import { langContext } from '../../../App';
import { langChangeMeetingCreatePermision } from './changeMeetingCreatePermissionTranslate';

const ChangeMeetingCreatePermission = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langChangeMeetingCreatePermision[lang]

    const {adminStore, userStore} = useStore()
    const [checked, setChecked] = useState(false)


    const changeSetting = async (e) => {
        socket.emit(SOCKET.PAGE_REFRESH, adminStore.active_username)
        setChecked(e.target.checked)
        await adminStore.changeSetting(e.target.checked)
        adminStore.getUsers()
    }


    useEffect(() => {
        const user = adminStore.users.find(el => el.username === adminStore.active_username)
        setChecked(user.create_meeting)
    }, [adminStore.active_username])
    return (
        <div className='basis-1/2 bg-white'>
            {showBySelected.tr_permissionToCreate}
            <div className='grid gap-3'>
                <Toggle onHandelChange={changeSetting} defaultChecked={checked}/>
                {/*<button type="submit" className="bg-green-500 p-2 rounded" onClick={() =>changeSetting(true)}>Թույլ Տալ</button>*/}
                {/*<button type="submit" className="bg-red-400 p-2 rounded" onClick={() => changeSetting(false)}>Թույլ Չտալ</button>*/}
            </div>
        </div>
    );
});

export default ChangeMeetingCreatePermission;