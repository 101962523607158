import { observer } from "mobx-react-lite";
import {useContext, useEffect, useState} from "react";
import { NotificationContainer } from 'react-notifications';
import SideNav from "../components/SideNav";
import SingleChat from "../components/PrivateChat";
import { useStore } from "../store";
import { socket } from "../const/socket"
import Modal from "../components/modal/CustomModal";
import General from "../container/General";
import SOCKET from "../const/socketNames"
import { AnswerCall } from "../components/modal/AnswerCall";
import User from "../states/User";
import { useNavigate } from "react-router-dom";
import {popUp} from "../utils/helpers";
import {  BACKEND_URL } from "../const/Constants";
import avatarImage from "../assets/images/user_avatar.png";
import { langContext } from "../App";
import { langGeneral } from "../container/generalTranslate";
import "./homepage.css"
import UserModal from "../components/modal/UserModal";
import Profile from "../container/Profile";


const Home = observer(({change}) => {

    const lang = useContext(langContext)
    let showBySelected = langGeneral[lang]

    const [navbar, setNavbar] = useState("")
    const [purpose, setPurpose] = useState("create")
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [userModal, setUserModal]=useState(false)
    const [pageSelection, setPageSelection] = useState("invite")


    const [roomId, setRoomId] = useState("")
    const [name, setName] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()
    const [closeMessagesModal, setCloseMessagesModal] = useState(false)
    const [groupChatOpened, setGroupChatOpened] = useState(false)
    const [searchInput,setSearchInput] = useState("")

    const {usersStore, userStore,chatStore, videoRoomStore} = useStore()

    useEffect(function () {
        if(userStore.role === "Guest") {
            userStore.logout()
            navigate("/")
        }else {
            socket.emit(SOCKET.JOIN_CONNECTED, userStore.user)
            if(!window.localStorage.getItem("existRoom")) {
                videoRoomStore.deleteMyOnlineStatus()
            }
            usersStore.getUsers();
        }

    }, [])

    useEffect(function () {
        socket.on(SOCKET.PAGE_REFRESH, () => {
            window.location.reload();
        });
        socket.on(SOCKET.PRIVATE_CALL_WAIT, ({name, room_id}) => {
            userStore.setReceivedCall(true)
            setRoomId(room_id)
            setName(name)
            openModal()
        })

        socket.on(SOCKET.SHUT_DOWN_CALL, () => {
            setIsOpen(false)
            window.location.reload();
        })

        return () =>
        {
            socket.off(SOCKET.PRIVATE_CALL_WAIT)
            socket.off(SOCKET.SHUT_DOWN_CALL)
            socket.off(SOCKET.PAGE_REFRESH)
        }
    },[])


    function openModal() {
        setIsOpen(true);
    }

    const changeNavbar = () => {
        setNavbar("profile")
    }

    function openModalPurpose(info) {
        setPurpose(info)

        setModalIsOpen(true);
    }

    const chatHandleClick = () => {
        if (chatStore.username !== 'ALL'){
            socket.emit(SOCKET.CHAT_CLOSE, { username: userStore.username, guestUsername: chatStore.username })
            chatStore.clearUsername()
            chatStore.clearMessages()
            chatStore.setUsername('ALL')
        }
    }

    function closeModal() {
        socket.emit(SOCKET.APPROVE_OR_CANCEL_PRIVATE_CALL, "cancel", name, User.username)
        socket.emit(SOCKET.SHUT_DOWN_CALL, name, User.username)
        setIsOpen(false);
    }

    const answerCall = () => {
        socket.emit(SOCKET.APPROVE_OR_CANCEL_PRIVATE_CALL, "approve", name , userStore.username)
        setIsOpen(false)
        popUp(`/video-room/${roomId}`)
    }

    const avatarImg = userStore.imagePath ? `${BACKEND_URL}${userStore.imagePath}` : avatarImage

    // navbar !== "profile" ? changeNavbar : () => setNavbar("")

    

    return (
        <>
        <div className="main-div" style={{"height": window.innerHeight}} onClick={() => setCloseMessagesModal(!closeMessagesModal)}>
            <div className="user-navbar">
             <span className="fontstyles titeles" style={{"margin-left" : "24px"}}>{showBySelected.tr_users}</span>
                <div className='display-right'>                    
                       <button onClick={() => chatHandleClick()} className="button fontstyles"><img  src="/mymsg.png" style={{"width": "15px", "height":"15px","border-radius": "0","margin-right":"5px"}}/>{showBySelected.tr_myMails}</button>
                       <button onClick={() => {usersStore.getUsers(''); setTimeout(() => openModalPurpose("chat"), 50); setGroupChatOpened(!groupChatOpened); setSearchInput("")}} className="button fontstyles"><img src="/shapegroup.png" style={{"width": "13px", "height":"13px","margin-right":"5px"}}/>{showBySelected.tr_groupMails}</button>
                    <div className='user-img-and-name' onClick={() => setUserModal(!userModal)}>
                      <img
                        src={userStore.imagePath ? `${BACKEND_URL}${userStore.imagePath}` : avatarImage}
                        className=" "
                        alt="Avatar" />
                
                      <p className="fontstyles titeles" style={{"cursor":"pointer"}}>
                        {userStore.username + " "}
                        { userStore.userData.name || userStore.userData.last_name || userStore.userData.father_name ? (
                        <span >
                            ({userStore.userData.name + " "}{userStore.userData.last_name + " "}{userStore.userData.father_name})
                        </span>) : ""}</p>
                    </div >
                    {
                        userModal && <UserModal setNavbar={setNavbar} setUserModal={setUserModal}/>
                    }

                </div>              
                
                
            </div>

            <div className="flex">
                <SideNav searchInput={searchInput} setSearchInput={setSearchInput} groupChatOpened={groupChatOpened} closeMessagesModal={closeMessagesModal} setCloseMessagesModal={setCloseMessagesModal}/>
                <General searchInput={searchInput} setSearchInput={setSearchInput} groupChatOpened={groupChatOpened} setGroupChatOpened={setGroupChatOpened} setNavbar={setNavbar} change={change} navbar={navbar} setPurpose={setPurpose} openModal={openModalPurpose} purpose={purpose} modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen}/>
            </div>

            {userStore.receivingCall ? (
                <Modal
                    isOpen={isOpen}
                    isClose={closeModal}
                >
                    <AnswerCall avatarImg={avatarImg} answerCall={answerCall} closeModal={closeModal} name={usersStore.fullName(usersStore.users.find(el => el.username === name))}/>
                </Modal>
            ) : null}

            {chatStore.user && <SingleChat />}
            <NotificationContainer />
        </div>
        <div className="mob-main-div">
        <div className="user-navbar">
             <span className="fontstyles titeles button" style={{"margin-left" : "14px","width" : "102px"}} onClick={()=> pageSelection === "invite" ? (() => {setPageSelection("users"); setNavbar("")})() : (() => {setPageSelection("invite"); setNavbar("")})()}>{pageSelection === "invite" ? "Հրավերներ" : showBySelected.tr_users}</span>
                <div className='display-right'>                    
                       <button onClick={() => chatHandleClick()} className="button fontstyles"><img  src="/mymsg.png" style={{"width": "12px", "height":"12px","border-radius": "0"}}/></button>
                       <button onClick={() => {usersStore.getUsers(''); setTimeout(() => openModalPurpose("chat"), 50); setGroupChatOpened(!groupChatOpened); setSearchInput("")}} className="button fontstyles" style={{"margin-right":"10px"}}><img src="/shapegroup.png" style={{"width": "12px", "height":"12px"}}/></button>
                    <div className='user-img-and-name' onClick={() => setUserModal(!userModal)}>
                      <img
                        src={userStore.imagePath ? `${BACKEND_URL}${userStore.imagePath}` : avatarImage}
                        className=" "
                        alt="Avatar" />
                
                      <p className="fontstyles titeles" style={{"cursor":"pointer"}}>
                        {userStore.username + " "}
                        { userStore.userData.name || userStore.userData.last_name || userStore.userData.father_name ? (
                        <span >
                            ({userStore.userData.name + " "}{userStore.userData.last_name + " "}{userStore.userData.father_name})
                        </span>) : ""}</p>
                    </div >
                    {
                        userModal && <UserModal setNavbar={setNavbar} setUserModal={setUserModal}/>
                    }

                </div>   
            </div>
                {navbar === "profile" ? <Profile setNavbar={setNavbar} change={change} navbar={navbar}/> : pageSelection === "invite" ? <div className="flex mob-sidenav">
                <SideNav searchInput={searchInput} setSearchInput={setSearchInput} groupChatOpened={groupChatOpened} closeMessagesModal={closeMessagesModal} setCloseMessagesModal={setCloseMessagesModal}/>
                </div> : <div className="flex">
                <General searchInput={searchInput} setSearchInput={setSearchInput} groupChatOpened={groupChatOpened} setGroupChatOpened={setGroupChatOpened} setNavbar={setNavbar} change={change} navbar={navbar} setPurpose={setPurpose} openModal={openModalPurpose} purpose={purpose} modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen}/>
            </div>}
       
                
            {userStore.receivingCall ? (
                <Modal
                    isOpen={isOpen}
                    isClose={closeModal}
                >
                    <AnswerCall avatarImg={avatarImg} answerCall={answerCall} closeModal={closeModal} name={usersStore.fullName(usersStore.users.find(el => el.username === name))}/>
                </Modal>
            ) : null}

            {chatStore.user && <SingleChat />}
            <NotificationContainer />
        </div>
        </>
    );
})

export default Home;