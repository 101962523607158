import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {NotificationContainer} from 'react-notifications';
import {useStore} from "../store"

import {ALERT_MESSAGE_TYPE_DANGER} from "../const/Constants"
import {passwordValidation, usernameValidation} from "../utils/validate";
import AlertComponent from "../utils/Alert";
import { langContext } from "../App";
import { langLogin } from "./loginTranslate";
import Lang from "../components/Lang/Lang"
import "./login.css"
import Footer from "../components/Footer";
import Logo from "../components/Logo";

const Login = observer((props) => {
    const lang = useContext(langContext);
    let showBySelected = langLogin[lang]

    let [showPass, setShowPass] = useState(true)

    const {userStore} = useStore()
    const [data, setData] = useState({
        username: "",
        password: "",
        confirm_password: ""
    })

    const [passwordDeleted, setPasswordDeleted] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()

    const handleChange = (e) => {
        const {name, value} = e.target
        setData({...data, [name]: value})
        setErrorMessage("")

        if (name === "username") {
            const result = usernameValidation(value)
            !result.isValid && setErrorMessage(result.message)
        }  else if(name ==="confirm_password"){
            data.password !== value && setErrorMessage(showBySelected.tr_notMatch)
        }else {
            const result = passwordValidation(value)
            !result.isValid && setErrorMessage(result.message)
        }
    }

    const refreshPassword =async ()  => {
        const result = await userStore.refreshPassword({ password: data.password})
        if(result.status) {
           await join()
        }else {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, result.message)
        }
    }

    async function join() {
        const {username, password} = data
        if(!username && !password) {
            setErrorMessage(showBySelected.tr_pleaseFill)
            return
        }
        if(password.length < 8) {
            setErrorMessage(showBySelected.tr_passLangValid)
            return
        }
        const result = await userStore.login({username, password})
        if (!result.status) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, result.message)
        } else {
           await userStore.getCurrentUser()
            if(userStore.user_settings.password_deleted){
                setPasswordDeleted(true)
                setData({...data, password: "", confirm_password: ""})
            }else{
                userStore.setUsername(username)
                navigate("/home")
            }
        }
    }

    return (
        <div className="login-page" style={{"height": window.innerHeight}}>
            <Lang change={props.change} value={props.value}/>
            <Logo />
            <div className="background-div" style={{"height": window.innerHeight}}>
                <div className="info-div">                        
                        <h1 className="fontstyle head1" >{showBySelected.tr_logIn}</h1>
                        <h3 className="fontstyle head2" >VKS</h3>
                        <h3 className="fontstyle head3" >{showBySelected.tr_confSystem}</h3>
                        {
                            passwordDeleted ?
                                <>  <p className="fontstyle label-p" >{showBySelected.tr_password} </p>
                                    <div className="info-input-div">
                                        <input type="password" name="password" placeholder={showBySelected.tr_password} value={data.password}
                                               onKeyDown={(event) => event.key === 'Enter' ? join() : ''}
                                               onChange={(e) => handleChange(e)}
                                               className="info-input"/>
                                    </div>
                                    <p className="fontstyle label-p" >{showBySelected.tr_passwordConfirm} </p>
                                    <div className="info-input-div">
                                        <input type="password" name="confirm_password" placeholder={showBySelected.tr_passwordConfirm} value={data.confirm_password}
                                               onKeyDown={(event) => event.key === 'Enter' ? join() : ''}
                                               onChange={(e) => handleChange(e)}
                                               className="info-input"/>
                                    </div>
                                    <div>
                                    </div>
                                    {errorMessage === '' ? null :
                                        <span className="text-red-500 text-sm text-center">{errorMessage}</span>}
                                    <div className="mt-7 w-full flex justify-center items-center">
                                        <div className='w-full'>
                                            <button onClick={() => refreshPassword()} disabled={!!errorMessage}
                                                    className="bg-blue-500  p-3 rounded-xl text-white shadow-xl hover:shadow-inner focus:outline-none transition duration-500 ease-in-out  transform hover:-translate-x hover:scale-105">
                                                {showBySelected.tr_passwordChange}
                                            </button>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <p className="fontstyle label-p" >{showBySelected.tr_name} </p>
                                    <div className="info-center">
                                        <div className="info-input-div">
                                            <img src="/Iconuser.png" className="icons-input"/>
                                            <input type="text" name="username" id="username" placeholder={showBySelected.tr_name} value={data.username}
                                                   onChange={(e) => handleChange(e)} className="info-input"/>
                                        </div>
                                    </div>
                                    <p className="fontstyle label-p" >{showBySelected.tr_password} </p>
                                    <div className="info-center">
                                        <div className="info-input-div">
                                            <img src="/Iconpass.png" className="icons-input" />
                                            <input type={showPass ? "password" : "text"} name="password" id="password" placeholder={showBySelected.tr_password} value={data.password}
                                                   onKeyDown={(event) => event.key === 'Enter' ? join() : ''}
                                                   onChange={(e) => handleChange(e)}
                                                   className="info-input"/>
                                            {showPass ? <img src="/Iconeye.png" className="eye" onClick={() => {setShowPass(!showPass)}}/> : <img src="/Iconcloseeye.png" className="eye" onClick={() => {setShowPass(!showPass)}}/>}
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                    {errorMessage === '' ? <div style={{"height": "14px"}}></div> :
                                        <span className="fontstyle" style={{"color":"tomato", "font-size":"14px"}}>{errorMessage}</span>}
                                        <div className="info-center">
                                            <button onClick={() => join()} disabled={!!errorMessage}
                                                    className="fontstyle">
                                                {showBySelected.tr_logIn}
                                            </button>
                                        </div>   
                                        <div className="info-center">
                                            <div className="help-and">
                                                <a href={lang === "arm" ? "/armsupport.pdf" : "/engsupport.pdf"} target="_blank" rel="noreferrer"
                                                   className="fontstyle">
                                                    {showBySelected.tr_help}
                                                </a>                                      
                                            
                                                <a href="/register"
                                                   className="fontstyle">
                                                    {showBySelected.tr_registr}
                                                </a>
                                            </div>
                                        </div>
                                    </>
                        }
                        <NotificationContainer/>
                </div>
            </div>
        < Footer />
        </div>
    );
})
export default Login;
