export const langLoggingList = {
    "arm": {
        tr_loginLogout: "Մուտք/Ելք",
        tr_login: "Մուտք",
        tr_logout: "Ելք"
    },
    "eng": {
        tr_loginLogout: "Login/Logout",
        tr_login: "Login",
        tr_logout: "Logout"
    },
    "fr": {
        tr_loginLogout: "Connexion/Déconnexion",
        tr_login: "Connexion",
        tr_logout: "Déconnexion"
    },
    "arb": {
        tr_loginLogout: "دخول/خروج",
        tr_login: "دخول",
        tr_logout: "خروج"
    }
}