import React, {memo, useState} from "react";
import Select from "react-select";


function SelectOption(props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const [inputValue, setInputValue] = useState(false);
    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);

    const handleInputChange = (newValue) => {
        // setInputValue(inputValue);
        return newValue.replace(/\W/g, '');
    };

    const style = {
        control: (base, state) => ({
            ...base,
            border: '1px solid #DFE3E9',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                border: '1px solid #DFE3E9'
            }
        })
    }


    return (<Select styles={{
        ...style,
    }}
            {...props}
            closeMenuOnSelect={false}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onInputChange={handleInputChange}
        />);
}

export default memo(SelectOption);