import { useContext, useRef, useState } from "react";
import VideoRoom from "../../states/VideoRoom";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import SelectOption from "../SelectOption";
import {useStore} from "../../store";
import { useEffect } from "react";
import { ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS } from "../../const/Constants";
import AlertComponent from "../../utils/Alert";
import {socket} from "../../const/socket";
import SOCKET from "../../const/socketNames"
import {getDateByMonthAndHour} from "../../utils/helpers"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import { langContext } from "../../App";
import { langChangeRoom } from "./changeRoomTranslate";




function ChangeRoom({ room, closeModal , setIsOpen}) {
    const lang = useContext(langContext);
    let showBySelected = langChangeRoom[lang]

    const [roomName, setRoomName] = useState(room.name)
    const [date, setDate] = useState(new Date(room.start_date))
    const [hour, setHour] = useState(new Date(room.start_date).toString().substr(16, 5))
    const {usersStore} = useStore();
    const [selected, setSelected] = useState([])
    const [options] = useState(function () {
        var res = [];
        Object.keys(usersStore.users).forEach((index) => {
            res = [...res, { value: usersStore.users[index].username, label: usersStore.users[index].name || usersStore.users[index].last_name ? usersStore.users[index].name + " " + usersStore.users[index].last_name : usersStore.users[index].username }]
        });
        return res;
    })
    const onChange = (selectedOptions) => setSelected(selectedOptions);

    useEffect(() => {
        setSelected(room.invited_users.map(el => ({ value: el, label: el })))
    }, [])



    const countRef = useRef("")


    const handleSubmit = async (e) => {
        e.preventDefault()
        const invited_users = selected.map(el => el.value)
    
        const newIsoDate = new Date(date.toISOString());
        newIsoDate.setHours(newIsoDate.getHours() + 4);
        const isoDate = new Date(getDateByMonthAndHour(newIsoDate.toISOString(), hour))
        isoDate.setHours(isoDate.getHours() - 4);
        const result = await VideoRoom.updateVideoRoomById({
            roomId: room._id,
            info: {
                room_name: e.target.room.value,
                count: countRef.current.value,
                start_date: isoDate.toISOString(),
                invited_users
            }
        })
        socket.emit(SOCKET.MEETING_CREATE,invited_users)
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        AlertComponent(status, result.message)
        closeModal()

        VideoRoom.getMyVideoMeetings()
    }



    const handleRoomNameChange = (event) => {
        setRoomName(event.target.value)
    }
    const chatCloseHandleClick = () => {
        setIsOpen(false)
    }
    return (
        <form onSubmit={handleSubmit} className="mk-sizes mk-font" style={{ maxWidth: '400px' }}>
            <div className="mk-main-div">
                <div className="mk-header">
                    <h2 className="mk-font-17"> {showBySelected.tr_changeData}</h2>

                    <button type="button" onClick={() => chatCloseHandleClick()}
                            className="">
                        <img src="/shapeclose.png" />
                    </button>
                </div>
                <div className="mk-16">
                    <label className="mk-font-17" htmlFor="room">{showBySelected.tr_meetingTitle} :</label>
                    <input
                       type="text"
                       placeholder={showBySelected.tr_meetingTitle}
                       id="room"
                       name="room"
                       maxLength={30}
                       className="mk-input"
                       value={roomName}
                       onChange={(e) => handleRoomNameChange(e)}
                       required
                     />
                </div>

                <div style={{"display":"none"}}>
                <label htmlFor="room">{showBySelected.tr_numberOfParticipants}:</label>
                <input
                    type="number"
                    id="participant_count"
                    name="count"
                    disabled
                    ref={countRef}
                    value={selected.length}
                    className="border-2 mb-2"
                    required
                />
                </div>
              
                <div className='mk-16'>
                    <p className="mk-font-17">{showBySelected.tr_participants}</p>
                    <SelectOption
                        isMulti
                        value={selected}
                        options={options}
                        onChange={onChange}
                        className="basic-multi-select mk-select-input"
                        classNamePrefix="select"
                        placeholder={showBySelected.tr_selectUsers}
                    />
                </div>
 
                <div className="mk-16">
                       <p className="mk-font-17">{showBySelected.tr_dayAndTime}</p>
                       <Calendar className="mk-calendar" value={date} onChange={setDate} minDate={new Date()} />
                       <div className="mk-time">
                           <label htmlFor="appt">{showBySelected.tr_selectTime}։ </label>
                           <input type="time" id="appt" name="appt" className="mk-font-17" required value={hour} onChange={(e) => setHour(e.target.value)} />
                       </div>
                </div>
                <div className="mk-btn-pos">
                    <button className="mk-btn" type="submit">{showBySelected.tr_acceptTheChange}</button>
                </div>
            </div>
        </form>
    );
}

export default ChangeRoom;