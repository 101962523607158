export const langToggle = {
    "arm": {
        tr_createMeeting: "Ստեղծել հանդիպում"
    },
    "eng": {
        tr_createMeeting: "Create a meeting"
    },
    "fr": {
        tr_createMeeting: "Créer une réunion"
    },
    "arb": {
        tr_createMeeting: "إنشاء اجتماع"
    }
}