import React, {useContext, useEffect, useState} from "react";
import VideoRoom from "../states/VideoRoom";
import {Menu} from "@headlessui/react";
import Modal from "../components/modal/CustomModal"
import AlertComponent from "../utils/Alert";
import {observer} from "mobx-react-lite";
import {ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS} from "../const/Constants";
import {useStore} from "../store";
import {ApproveOrCancel} from "../components/modal/ApproveOrCancel";
import {SocketContext} from "../const/socket"
import SOCKET from "../const/socketNames"
import {options, classNames, getClassByStatus, popUp} from "../utils/helpers"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faPlay, faTimes} from "@fortawesome/free-solid-svg-icons";
import { langContext } from "../App";
import { langMeetingList } from "./meetingsListTranslate";

const getRoomIconByStatus = ({status, username, refused_users}) => {

    let language = localStorage.getItem("langFromStorage")
    let showTranslated = langMeetingList[language]
    if (status === "pending") {
        if (refused_users.includes(username))
            return showTranslated.tr_late

        return showTranslated.tr_overdue
    } else if (status === "declined") {
        return showTranslated.tr_cancelled
    } else if (status === "started") {
        if (refused_users.includes(username))
            return showTranslated.tr_late

        return showTranslated.tr_started
    } else if(status === "warning"){
        return showTranslated.tr_late
    } else {
        return showTranslated.tr_established
    }

}


const UpComingMeetingsList = observer(({title}) => {

    const lang = useContext(langContext)
    let showBySelected = langMeetingList[lang]

    const [modalIsOpen, setIsOpen] = useState(false);
    const [decline, setDecline] = useState(false)
    const [selectedRoom, setSelectedRoom] = useState("")
    const [deleteMeeting, setDeleteMeeting] = useState(false)
    const {userStore} = useStore()

    // TODO is dublicated in Meetingroom
    const [dateState, setDateState] = useState(new Date());

    useEffect(() => {
        setInterval(() => setDateState(new Date()), 30000);
    }, []);
    // END

    const socket = useContext(SocketContext)


    useEffect(() => {
        VideoRoom.getMeInvitedVideoMeetings()

        socket.on(SOCKET.MEETINGS_ME_INVITED, () => {
            VideoRoom.getMeInvitedVideoMeetings()
        })
        socket.on(SOCKET.MEETING_STARTED, () => {
            VideoRoom.getMeInvitedVideoMeetings()
        })

        return () => socket.off(SOCKET.MEETINGS_ME_INVITED)
    }, [])

    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
        setDecline(false)
        setDeleteMeeting(false)
    }

    // get_me_invited_meetings
    const connectMeeting = async (roomId, start_date) => {
        const [myHasBusy] = await Promise.all([userStore.checkUserIsBusy({username: userStore.username})])

        if (!myHasBusy.status) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_alreadyInCall)
            return
        }

        if (new Date().toISOString() < start_date) {
            const date = new Date(start_date).toString().split(" ")
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_meetingdate(date[1],date[2],date[4]))
        } else {
            popUp(`/video-room/${roomId}?purpose=Group`)
        }

    }

    const declineInviteMeeting = (room_id) => {
        setSelectedRoom(room_id)
        setDecline(true);
        openModal()
    }

    const approveVideoMeetingDecline = async (reasonMessage) => {
        const result = await VideoRoom.declineMeInviteMeeting(selectedRoom._id)
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        if (result.status) {
            await VideoRoom.getMeInvitedVideoMeetings()
        }
        socket.emit(SOCKET.APPROVE_OR_DECLINE_MEETING, [selectedRoom.username], reasonMessage, userStore.username)
        AlertComponent(status, result.message)
        closeModal()
    }

    const cancelVideMeetingDecline = () => {
        closeModal()
    }

    const approveMeetingDelete = async () => {
        const result = await VideoRoom.deleteMeInviteMeeting(selectedRoom._id)
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        AlertComponent(status, result.message)
        if (result.status) {
            await VideoRoom.getMeInvitedVideoMeetings()
        }
        setSelectedRoom({})
        closeModal()
    }

    const checkLocaleTime = (roomDate, type) => {
        if (type === 'pending') {
            const roomTime = new Date(roomDate).toLocaleTimeString('en', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                hour12: false,
                minute: 'numeric'
            });
            return dateState.toLocaleTimeString('en', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                hour12: false,
                minute: 'numeric'
            }) > roomTime;
        } else
            return false;
    }

    const isDeclined = (room) => {
        if (!room.invited_users.some(el => el === userStore.username)){
            return 'declined';
         }
        return 'warning';
    }

    return (
        <>
            <div className="meeting-list-pc">
                <div className="listtitle-div">
                    <span className='fontstyles font-bold titeles' style={{"margin-left":"16px"}}>{title}</span>
                </div>
                <table className="w-full">
                    <thead>
                    <tr>
                        <th className="describtions fontstyles">{showBySelected.tr_name}</th>
                        <th className="describtions fontstyles"><div className="dash"></div>{showBySelected.tr_participant}</th>
                        <th className="describtions fontstyles"><div className="dash"></div>{showBySelected.tr_time}</th>
                        <th className="describtions fontstyles"><div className="dash"></div>{showBySelected.tr_status}</th>
                        <th style={{"width" :"50px"}} className="describtions"><div className="dash"></div></th>
                    </tr>
                    </thead>
                    <tbody>
                    {VideoRoom.invite_meetings.map((room, idx) => (
                        <Menu key={idx} as="tr" className="fontstyles hover:text-gray-800 hover:bg-gray-100 list-tr">
                            <td className='list-td'>{room.name}</td>
                            <td as={'td'}
                                         className='list-td'>{room.participant_count}</td>
                            <td as={'td'}
                                         className='list-td'>{new Date(room.start_date).toLocaleDateString('en-GB', options)}</td>
                            <td as={'td'}><div className={"status-td " + getClassByStatus(checkLocaleTime(room.start_date, room.status) ? isDeclined(room) : room.status)}>
                                {getRoomIconByStatus({
                                    status: checkLocaleTime(room.start_date, room.status) ? isDeclined(room) : room.status,
                                    refused_users: room.refused_users,
                                    username: userStore.username
                                })}</div>
                            </td>
                            <Menu.Button as={'td'} className="hover:cursor-pointer"><div className="settings-img"><img src="/Iconsettings.png" /></div>
                            <Menu.Items as={'td'}
                                        className="list-control-div">
                                <div className="py-1">
                                    {
                                        room.status === "started" && !room.refused_users.includes(userStore.username) && <>
                                            <Menu.Item >
                                                    <button
                                                        onClick={() => connectMeeting(room._id, room.start_date)}
                                                        className='list-controller-btn participate-controller'><img src="/participate.png"/>{showBySelected.tr_toParticipate}</button>                                              
                                            </Menu.Item>
                                            <hr/>

                                        </>
                                    }
                                    {
                                        room.status !== "approved" && room.status !== "declined" && !room.refused_users.includes(userStore.username) &&
                                        <Menu.Item>                                            
                                                <button
                                                    onClick={() => {
                                                        declineInviteMeeting(room._id);
                                                        setSelectedRoom(room)
                                                    }}
                                                    className="list-controller-btn cencel-controller"><img src="/cencelred.png" />{showBySelected.tr_cancel}</button>                                            
                                        </Menu.Item>
                                    }

                                    {
                                        <Menu.Item>
                                            
                                                <button onClick={() => {
                                                    setDeleteMeeting(true);
                                                    setSelectedRoom(room);
                                                    openModal()
                                                }} className="list-controller-btn cencel-controller"><img src="/deletered.png" />{showBySelected.tr_delete}</button>
                                        </Menu.Item>
                                    }
                                </div>
                            </Menu.Items>
                            </Menu.Button>

                        </Menu>
                    ))}

                    </tbody>
                </table>

                {
                    decline &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>
                        <ApproveOrCancel approveHandler={approveVideoMeetingDecline}
                                         cancelHandler={cancelVideMeetingDecline} title={showBySelected.tr_canclReason}/>

                    </Modal>
                }

                {
                    deleteMeeting &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>

                        <ApproveOrCancel approveHandler={approveMeetingDelete} cancelHandler={closeModal}
                                         reason_need={false}/>
                    </Modal>
                }
            </div>


    {/* mob */}
    <div className="meeting-list-mob">
                <div className="listtitle-div">
                    <span className='fontstyles font-bold titeles' style={{"margin-left":"16px"}}>{title}</span>
                </div> 
                <div className="mob-conf-list-my">              
                    {VideoRoom.invite_meetings.map((room, idx) => (<div className="mob-each-conf">
                        <Menu key={idx} as="tr" className="fontstyles list-tr">
                            <Menu.Button as={'div'} className="my-mob-list">
                                <div><p className="headers">{showBySelected.tr_name}</p><p className='list-td'>{room.name}</p></div>
                                <hr className="headers-div"/>
                                <div><p className="headers">{showBySelected.tr_participant}</p><p className='list-td'>{room.participant_count}</p></div>
                                <hr className="headers-div"/>
                                <div><p className="headers">{showBySelected.tr_time}</p><p className='list-td'>{new Date(room.start_date).toLocaleDateString('en-GB', options)}</p></div>
                                <hr className="headers-div"/>
                                <div><p className={"status-td " + getClassByStatus(checkLocaleTime(room.start_date, room.status) ? isDeclined(room) : room.status)}>
                                {getRoomIconByStatus({
                                    status: checkLocaleTime(room.start_date, room.status) ? isDeclined(room) : room.status,
                                    refused_users: room.refused_users,
                                    username: userStore.username
                                })}</p>
                            </div>
                            
                            <Menu.Items as={'td'}
                                        className="list-control-div">
                                <div className="py-1">
                                    {
                                        room.status === "started" && !room.refused_users.includes(userStore.username) && <>
                                            <Menu.Item >
                                                    <button
                                                        onClick={() => connectMeeting(room._id, room.start_date)}
                                                        className='list-controller-btn participate-controller'><img src="/participate.png"/>{showBySelected.tr_toParticipate}</button>                                              
                                            </Menu.Item>
                                            <hr/>

                                        </>
                                    }
                                    {
                                        room.status !== "approved" && room.status !== "declined" && !room.refused_users.includes(userStore.username) &&
                                        <Menu.Item>                                            
                                                <button
                                                    onClick={() => {
                                                        declineInviteMeeting(room._id);
                                                        setSelectedRoom(room)
                                                    }}
                                                    className="list-controller-btn cencel-controller"><img src="/cencelred.png" />{showBySelected.tr_cancel}</button>                                            
                                        </Menu.Item>
                                    }

                                    {
                                        <Menu.Item>
                                            
                                                <button onClick={() => {
                                                    setDeleteMeeting(true);
                                                    setSelectedRoom(room);
                                                    openModal()
                                                }} className="list-controller-btn cencel-controller"><img src="/deletered.png" />{showBySelected.tr_delete}</button>
                                        </Menu.Item>
                                    }
                                </div>
                            </Menu.Items>
                            </Menu.Button>

                        </Menu>
                        </div>
                        
                    ))}</div> 


                {
                    decline &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>
                        <ApproveOrCancel approveHandler={approveVideoMeetingDecline}
                                         cancelHandler={cancelVideMeetingDecline} title={showBySelected.tr_canclReason}/>

                    </Modal>
                }

                {
                    deleteMeeting &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>

                        <ApproveOrCancel approveHandler={approveMeetingDelete} cancelHandler={closeModal}
                                         reason_need={false}/>
                    </Modal>
                }
            </div>
        </>
    )
})

export default UpComingMeetingsList