import React, {useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import FilterMessages from "../components/FilterMessages";
import {useStore} from "../../../store";
import {options} from "../../../utils/helpers";
import { langContext } from '../../../App';
import { langMessageList } from './messageListTranslate';

let a = 0
const MessageList = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langMessageList[lang]

    console.warn(`Message List count ${++a}`)

    const {adminStore} = useStore()

    // useEffect(() => {
    //     VideoRoom.getAllVideoMeetings()
    // }, [])

    return (
        <div className="flex flex-col    basis-9/12">
            <div className='border-b p-3'>
                <span className='font-bold'>{showBySelected.tr_messageList}</span>
            </div>
            <FilterMessages/>
            <div className='overflow-y-scroll'>
                <table className="border-collapse border border-l-0  border-slate-400 w-full">
                    <thead>
                    <tr>
                        <th className="border border-slate-300 border-l-0">{showBySelected.tr_type}</th>
                        {/* <th className="border border-slate-300 ">{showBySelected.tr_participant}</th> */}
                        <th className="border border-slate-300 ">{showBySelected.tr_time}</th>
                        <th className="border border-slate-300 ">{showBySelected.tr_volume}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {adminStore.messages_logs.map((message, idx) => (
                        <tr key={message._id}>
                            <td className='border border-slate-300 border-l-0'>{message.type === "Message"? showBySelected.tr_message: showBySelected.tr_file}</td>
                            {/*<td className='border border-slate-300 border-l-0'>Սաշաին</td>*/}
                    <td className='border border-slate-300 border-l-0'>{new Date(message.created_at).toLocaleDateString('en-GB', options)}</td>
                    <td className='border border-slate-300 border-l-0'>{message.size}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
});

export default MessageList;