export const langFilterMeeting = {
    "arm": {
        tr_conference: "Կոնֆերանս",
        tr_till: "Մինչև",
        tr_selectUsers: "Ընտրել օգտվողներին",
        tr_search: "Որոնում"
    },
    "eng": {
        tr_conference: "Conference",
        tr_till: "Till / up to",
        tr_selectUsers: "Select users",
        tr_search: "Search"
    },
    "fr": {
        tr_conference: "Conférence",
        tr_till: "Jusqu’au",
        tr_selectUsers: "Sélectionner les utilisateurs",
        tr_search: "Recherche"
    },
    "arb": {
        tr_conference: "مكالمة جماعية",
        tr_till: "لغاية",
        tr_selectUsers: "اختيار المستخدمين",
        tr_search: "بحث"
    }
}