import { createContext } from "react";
import socketio from "socket.io-client";
import { SOCKET_URL } from "./Constants";

const options = process.env.REACT_APP_ENV === "production" ? {
    secure: true,
    reconnection: true,
    reconnectionAttempts: 10
} : {
    secure: false,
    reconnection: true,
    rejectUnauthorized: false,
    reconnectionAttempts: 10
}


export const socket = socketio.connect(SOCKET_URL, options);
export const SocketContext = createContext();
