export const langIndex = {
    "arm": {
        tr_generatePin: "Գեներացնել Pin"
    },
    "eng": {
        tr_generatePin: "Generate a pin"
    },
    "fr": {
        tr_generatePin: "Générer un PIN"
    },
    "arb": {
        tr_generatePin: "إنشاء كلمة مرور"
    }
}