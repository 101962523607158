import React, { useContext } from 'react';
import MeetingList from "./MeetingList";
import UserLogs from "./UserLogs";
import {observer} from "mobx-react-lite";
import Users from "../../../states/Admin/Users";
import Edit from "../Edit";
import User from "../../../states/User";
import {BACKEND_URL} from "../../../const/Constants";
import avatarImage from "../../../assets/images/user_avatar.png";
import LogOutSvg from "../../../components/svg/LogOutSvg";
import GeneratePin from "./GeneratePin";
import { langContext } from '../../../App';
import { langIndex } from './indexTranslate';

let a = 0
const Content = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langIndex[lang]
    const logout = async () => {
        await User.logout()
        window.location = '/'
    }


    return (
        <div className="flex flex-col w-hull basis-11/12 home">
            <div className="flex w-full justify-between items-center p-3 border-b border-gray-200 h-16 bg-white">
                <div className='shrink-0'>
                    <img
                        src={User.userData.image ? `${BACKEND_URL}${User.userData.image}` : avatarImage}
                        className="p-1 w-10 h-10 rounded-full hover:cursor-pointer ring-2 ring-blue-300 dark:ring-blue-500"
                        style={{borderRadius: "50%"}}
                        alt="Avatar" onClick={() => Users.content = 'meeting'}/>
                </div>
                <div className="w-full text-start ">
                    <p className="text-sm px-2 font-semibold text-blue-600">{User.username} <span
                        className="text-xs text-black">( Administrator )</span></p>
                </div>


                <div className="flex items-center ">
                    <button className="bg-green-300 h-5 w-36 rounded-lg m-2  text-sm"
                            onClick={() => Users.changeContent('pin', null)}> {showBySelected.tr_generatePin}
                    </button>
                    <div onClick={() => logout()}>
                        <LogOutSvg/>
                    </div>
                </div>
            </div>

            {Users.content === "meeting" && <MeetingList/>}
            {Users.content === "user_log" && <UserLogs/>}
            {Users.content === "user_edit" && <Edit/>}
            {Users.content === "pin" && <GeneratePin/>}
        </div>
    );
});

export default Content;