import React, {useContext, useEffect, useState} from 'react';
import VideoRoom from "../../../states/VideoRoom";
import {Menu} from "@headlessui/react";
import Modal from "../../../components/modal/CustomModal";
import {ApproveOrCancel} from "../../../components/modal/ApproveOrCancel";
import {observer} from "mobx-react-lite";
import FilterMeeting from "../components/FilterMeeting";
import {useStore} from "../../../store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faPlay, faTimes} from "@fortawesome/free-solid-svg-icons";
import {socket} from "../../../const/socket";
import SOCKET from "../../../const/socketNames";
import { langContext } from '../../../App';
import { langMeetingList } from './meetingListTranslate';


const getRoomIconByStatus = (status) => {
    switch (status) {
        case "approved":
            return <FontAwesomeIcon icon={faCheck} className="fa fa-check text-green-500" aria-hidden="true"/>
        case "declined":
            return <FontAwesomeIcon icon={faTimes} className="fas fa-times text-red-500"/>
        case "started":
            return <FontAwesomeIcon icon={faPlay} className="far fa-play"/>
        default:
            return <FontAwesomeIcon icon={faClock} className="far fa-clock"/>
    }
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

let a = 0
const MeetingList = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langMeetingList[lang]

    console.warn(`Meeting List count ${++a}`)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [decline, setDecline] = useState(false)
    const [selectedRoom, setSelectedRoom] = useState({})

    const {adminStore} = useStore()

    useEffect(() => {
        // VideoRoom.getAllVideoMeetings()
    }, [])

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
        setDecline(false);
    }
    const approveVideoMeetingDecline = async () => {
        adminStore.declineMeeting(selectedRoom._id);
        socket.emit(SOCKET.PAGE_REFRESH, selectedRoom.username)
        closeModal()
    }

    return (
        <div className="flex flex-col border-r border-slate-300 overflow-hidden  basis-full">
            <div className='border-b p-3'>
                <span className='font-bold'>{showBySelected.tr_meetingList}</span>
            </div>
            <FilterMeeting/>
            
            <div className='overflow-y-scroll'>
                <table className="border-collapse border border-slate-400 w-full">
                    <thead>
                    <tr>
                        <th className="border border-slate-300 ">{showBySelected.tr_name}</th>
                        <th className="border border-slate-300 ">{showBySelected.tr_participant}</th>
                        <th className="border border-slate-300 ">{showBySelected.tr_time}</th>
                        <th className="border border-slate-300 ">{showBySelected.tr_status}</th>
                    </tr>
                    </thead>
                    {/*<InfiniteScroll */}
                    {/*      dataLength={50}*/}
                    {/*      next={VideoRoom.getAllVideoMeetings()}*/}
                    {/*      hasMore={true}*/}
                    {/*     // loader={}*/}
                    {/*>*/}
                    <tbody className=''>
                    {VideoRoom.allMeetings.map((room, idx) => (
                        <Menu key={idx} as="tr"
                              className={"relative p-1 border text-gray-500 hover:text-gray-800 hover:bg-gray-100 hover:cursor-pointer "}>
                            <Menu.Button as={'td'} className='border border-slate-300 '>{room.name}</Menu.Button>
                            <Menu.Button as={'td'}
                                         className='border border-slate-300 '>{room.invited_users.length + 1}</Menu.Button>
                            <Menu.Button as={'td'}
                                         className='border border-slate-300 '>{new Date(room.start_date).toString().substring(0, 21)}</Menu.Button>
                            <Menu.Button as={'td'}
                                         className='border border-slate-300 '>{getRoomIconByStatus(room.status)}</Menu.Button>
                            { room.status === 'pending' && (<Menu.Items as={'td'}
                                                                        className="absolute z-10 w-40 right-0 rounded-md shadow-lg bg-white  focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({active}) => (
                                            <button onClick={() => {
                                                setDecline(true);
                                                setSelectedRoom(room);
                                                openModal();
                                            }} className={
                                                classNames(active ? 'bg-gray-100 text-red-500' : 'text-red-700',
                                                    'block px-4 py-2 text-sm'
                                                )}>{showBySelected.tr_block}</button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>)}
                        </Menu>
                    ))}
                    </tbody>
                    {/*</InfiniteScroll>*/}
                </table>
            </div>
            
            {
                decline &&
                <Modal
                    isClose={() => closeModal()}
                    isOpen={modalIsOpen}>

                    <ApproveOrCancel approveHandler={approveVideoMeetingDecline} cancelHandler={closeModal} reason_need={false}/>
                </Modal>
            }
        </div>

    );
});

export default MeetingList;