import React, { useState } from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideo, faVideoSlash} from "@fortawesome/free-solid-svg-icons";

const CameraButton = ({peerInstance}) => {
    const [mute, setMute] = useState(true)

    const disabledCamera = () => {
        peerInstance.toggleCamera(mute)
        setMute(!mute)
    }

    return (
        <div id="stopVideo" className={`options__button`} onClick={disabledCamera}>
            {
                mute ? (
                    <img src="/Iconcamera.png"/>
                    )
                    :
                    <img src="/IconcameraMute.png"/>
            }
        </div>
       
    )
}

export default CameraButton