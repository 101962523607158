import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { NotificationContainer } from 'react-notifications';
import {useStore} from "../store"
import {ALERT_MESSAGE_TYPE_DANGER} from "../const/Constants"
import { passwordValidation, usernameValidation } from "../utils/validate";
import AlertComponent from "../utils/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { langContext } from "../App";
import { langRegister } from "./registerTranslate";
import "./login.css"
import Lang from "../components/Lang/Lang";
import Footer from "../components/Footer";
import Logo from "../components/Logo";

const Login = observer((props) => {
    const lang = useContext(langContext);
    let showBySelected = langRegister[lang]

    const {userStore} = useStore()
    const [data, setData] = useState({
        username: "",
        password: "",
        confirm_password: "",
        pin_code: "",
    })
    const [errorMessage, setErrorMessage] = useState('')
    const [viewpassword, setViewPassword] = useState("password")
    const [viewrepeat,setViewRepeat] = useState("password")

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })

        if (name === "username") {
            const result = usernameValidation(value)
            !result.isValid ? setErrorMessage(result.message): setErrorMessage("")
        }else if(name ==="pin_code"){

        } else if(name ==="confirm_password"){
            const result = passwordValidation(value)
            !result.isValid ? setErrorMessage(result.message): setErrorMessage("")
            data.password !== value && setErrorMessage(showBySelected.tr_passwordDontMatch)
        }
        else {
            const result = passwordValidation(value)
            !result.isValid ? setErrorMessage(result.message): setErrorMessage("")
        }

    }

    async function join() {
        const { username, password, pin_code, confirm_password } = data
        if(!password && !username && !pin_code && !confirm_password) {
            setErrorMessage(showBySelected.tr_pleaseFill)
            return
        }
        if(password !== confirm_password){
            setErrorMessage(showBySelected.tr_passwordDontMatch)
            return 
        }
        if(username.length < 3){
            setErrorMessage(showBySelected.tr_minAndMaxLength)
            return
        }
        if(errorMessage) {
            return
        }
        const checkUsernameValid = usernameValidation(username)
        if(!checkUsernameValid.isValid) {
            setErrorMessage(checkUsernameValid.message)      
        }
        if(password.length < 8) {
            setErrorMessage( showBySelected.tr_passLengthValid)
        }
        const result = await userStore.register({ username, password, pin_code })
        if (!result.status) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, result.message)
        } else {
            navigate("/")
        }

    }

    const handleViewPassword = () =>{
        if(viewpassword === "password" ){
            setViewPassword("text")
        }else{
            setViewPassword("password")
        }

    }

    const handleViewRepeat= () =>{
        if(viewrepeat === "password" ){
            setViewRepeat("text")
        }else{
            setViewRepeat("password")
        }

    }

    return (
        <div className="login-page" style={{"height": window.innerHeight}}>
            <Lang change={props.change} value={props.value}/>
            <Logo />
            <div className="background-div" style={{"height": window.innerHeight}}>
                <div className="info-div">
                    <h1 className="fontstyle head1" >{showBySelected.tr_register}</h1>
                    <h3 className="fontstyle head2" >VKS</h3>
                    <h3 className="fontstyle head3" >{showBySelected.tr_confSystem}</h3>                    
                    <p className="fontstyle label-p" >{showBySelected.tr_name} </p>
                    <div className="info-center">
                        <div className="info-input-div">
                         <img src="/Iconuser.png" className="icons-input"/>
                         <input type="text" name="username" placeholder={showBySelected.tr_name} value={data.username}
                                onChange={(e) => handleChange(e)}
                                className="info-input" />
                        </div>
                    </div>
                    <p className="fontstyle label-p">{showBySelected.tr_password} </p>
                    <div className="info-center">
                        <div className="info-input-div">
                            <img src="/Iconpass.png" className="icons-input" />
                            <input type={`${viewpassword}`} name="password" placeholder={showBySelected.tr_password} value={data.password}
                                onKeyDown={(event) => event.key === 'Enter' ? join() : ''}
                                onChange={(e) => handleChange(e)}
                                className="info-input" />
                            {viewpassword==="password" ? <img src="/Iconeye.png" className="eye" onClick={handleViewPassword}/>
                            : <img src="/Iconcloseeye.png" className="eye" onClick={handleViewPassword}/>}                            
                        </div>
                    </div>
                    <p className="fontstyle label-p">{showBySelected.tr_repeatPass} </p>
                    <div className="info-center">
                        <div className="info-input-div">
                        <img src="/Iconpass.png" className="icons-input" />
                            <input type={`${viewrepeat}`} name="confirm_password" placeholder={showBySelected.tr_repeatPass} value={data.confirm_password}
                                onChange={(e) => handleChange(e)}
                                className="info-input"/>
                            {viewrepeat ==="password" ? <img src="/Iconeye.png" className="eye" onClick={handleViewRepeat}/>
                            : <img src="/Iconcloseeye.png" className="eye" onClick={handleViewRepeat}/>}
                        </div>
                    </div>
                    <p className="fontstyle label-p" >{showBySelected.tr_fillThePin} </p>
                    <div className="info-center">
                        <div className="info-input-div">
                        <img src="/Iconpin.png" className="icons-input" />
                            <input type="password" name="pin_code" placeholder={showBySelected.tr_fillThePin} value={data.pin_code}
                                   onKeyDown={(event) => event.key === 'Enter' ? join() : ''}
                                   onChange={(e) => handleChange(e)}
                                   className="info-input" />
                        </div>
                    </div>
                        {errorMessage ? 
                            <span className="fontstyle" style={{"color":"tomato", "font-size":"14px"}}>{errorMessage}</span>
                            :<div style={{"height":"14px"}}></div>}
                        <div className="info-center">
                                <button onClick={() => join()} disabled={!!errorMessage}
                                    className=" fontstyle">
                                    {showBySelected.tr_register}
                                </button>
                        </div>
                        <div className="info-center">
                            <div className="help-and">
                                <a href={lang === "arm" ? "/armsupport.pdf" : "/engsupport.pdf"} rel="noreferrer" target="_blank"
                                    className="fontstyle">
                                    {showBySelected.tr_help}
                                </a>                           
                            
                                <a href="/"
                                    className="fontstyle">
                                    {showBySelected.tr_logIn}
                                </a>
                            </div>
                        </div>
                        <NotificationContainer />                    
                </div>
            </div>
            < Footer />
        </div>
    );
})
export default Login;
