import "./App.css";
import 'react-notifications/lib/notifications.css';
import React, { useEffect, useState } from "react";
import Login from "./pages/Login";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import { SocketContext, socket } from "./const/socket";
import { AdminAuthenticated, RequireAuth } from "./HOC/RequireAuth"
import Dashboard from "./pages/Admin/Dashboard";
import Room from "./pages/Room";
import  MeetingGuest  from "./pages/MeetingGuest";
import PrivateChat from "./components/PrivateChat";
import Chat from "./pages/Admin/Chat";
import { axiosInstance } from "./const/axiosInstance";

export let langContext = React.createContext()


function App() {
  let initialLang = localStorage.getItem("langFromStorage") || "arm";

  const [selectedLang, setSelectedLang] = useState(initialLang);

  useEffect(() => {
    localStorage.setItem("langFromStorage", selectedLang)
    axiosInstance.post("/getLang", {language: selectedLang})
  }
      , 
      [selectedLang]);

  function langChange(value) {
      setSelectedLang(value)
      }
  return (
    <div>
      <SocketContext.Provider value={socket}>
      <langContext.Provider value={selectedLang}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Login change={langChange} value={selectedLang}/>} />
            <Route path="/register" element={<Register change={langChange} value={selectedLang}/>} />
            <Route path="/home" element={<RequireAuth><Home change={langChange}/></RequireAuth>} />
            <Route path="/video-room/:roomId" element={<RequireAuth><Room /></RequireAuth>} />
            <Route path="/chat-admin" element={<RequireAuth><PrivateChat/></RequireAuth>}/>
            <Route path="/dashboard" element={<AdminAuthenticated><Dashboard/></AdminAuthenticated>}/>
            <Route path="/chat" element={<RequireAuth><Chat/></RequireAuth>}/>
            <Route path="/video-guest" element={<MeetingGuest/>}/>
            <Route  path="/*" element={<Login />} />
          </Routes>
        </Router>
        </langContext.Provider>
      </SocketContext.Provider>

    </div>

  );
}

export default App;
