import React, { useEffect, useRef, useState } from 'react'
import User from '../states/User'
import { observer } from "mobx-react-lite"
import MicButton from '../components/Mic'
import CameraButton from '../components/Camera'
import Invite from '../components/Invite'
import MeetingParticipants from "../components/MeetingParticipants"
import { useStore } from '../store'
import { MeetingChat } from '../components/MeetingChat'
import { getQueryByParameter } from "../const/axiosInstance"
import { MeetingTime } from '../components/MeetingClcok'
import Split from "react-split";
import { socket } from "../const/socket";
import SOCKET from "../const/socketNames"
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faComment, faDesktop, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import PEERConnection from "../utils/WebRTCHandler";
import "./room.css"

const Room = observer(() => {
    const peerInstance = useRef()
    const [currentRoom, setCurrentRoom] = useState({})
    const [displayStream, setDisplayStream] = useState(false)

    const params = useParams()
    const ROOM_ID = params.roomId
    const PURPOSE = getQueryByParameter("purpose")
    const { videoRoomStore, userStore } = useStore()
    const navigate = useNavigate()


    useEffect(() => {
        window.addEventListener("beforeunload", () => {
            window.localStorage.removeItem("existRoom")
        })
        return () => {
            peerInstance.current?.destroyConnection();
        }
    }, []);

    useEffect(() => {
        const hasRoom = localStorage.getItem("existRoom")
        if (User.username && !hasRoom) {
            peerInstance.current = new PEERConnection({
                userDetails: {
                    username: User.username,
                    updateInstance: updateFromInstance
                }, roomId: ROOM_ID
            })
            localStorage.setItem("existRoom", true)
        } else {
            navigate("/home")
        }
        videoRoomStore.getVideoRoomByRoomId(ROOM_ID, PURPOSE).then(res => setCurrentRoom(res))
        videoRoomStore.getMeetingAllParticiapantsById(ROOM_ID)
        return () => {
            localStorage.removeItem("existRoom")
            userStore.setIsBusy(false)
        }
    }, [])

    const updateFromInstance = (key, value) => {
        if (key === 'displayStream') setDisplayStream(value);
    }
    useEffect(() => {
        if (Object.keys(currentRoom).length && PURPOSE==="Group") {
            if (["approved", "declined"].includes(currentRoom.status)) {
                navigate("/home")
                 return
            }
            if (userStore.role !== "Guest" && userStore.username !== currentRoom.username &&
                !currentRoom.invited_users.includes(userStore.username)) {
                navigate("/home")
            }
            if(userStore.role ==="Guest" && currentRoom.refused_users.includes(userStore.username)){
                navigate("/home")
            }
        }
    }, [currentRoom])

    useEffect(() => {
        const showChat = document.querySelector("#showChat");
        showChat.addEventListener("click", () => {
            if (document.querySelector(".main__right").style.display === 'none') {
                document.querySelector(".main__right").style.display = "flex";
                document.querySelector(".main__left").style.display = "flex";
            } else {
                document.querySelector(".main__left").style.display = "flex";
                document.querySelector(".main__right").style.display = "none"
                document.querySelector(".main__left").style.flex = "1";
            }
        });
    }, [])

    const handleLeaveCall = () => {
        if (User.username === currentRoom.username) {
            videoRoomStore.finishedMeeting(ROOM_ID)
        }
        if (User.role === "Guest") {
            window.localStorage.removeItem("username")
        }
        socket.emit(SOCKET.MEETING_USER_DISCONNECT, PURPOSE, User.username)
        peerInstance.current.destroyConnection()
        window.close()
    }

    const toggleScreenShare = () => {
        const { reInitializeStream } = peerInstance.current;
        reInitializeStream(false, true, !displayStream ? 'displayMedia' : 'userMedia').then(() => {
            setDisplayStream(!displayStream);
        });
    }

    const showInterface = () => {
        const element = document.getElementsByClassName('options')
        element[0].classList.toggle('options-hidden')
    }

    return (
        <>
            <div className="header flex justify-between ">
                {/* <div className='ml-3 text-2xl hover:cursor-pointer ' onClick={() => showInterface()}>
                </div> */}
                <div className="room-name-time">
                    <h3 className="" style={{"font-weight":"bold"}}>{currentRoom.name}</h3>
                    <MeetingTime
                        start_date={currentRoom.start_date ? currentRoom.start_date : currentRoom.created_at} />
                </div>
                <div></div>
            </div>
            <Split className="main conf-body" sizes={[70, 30]} minSize={[1000, 350]}>
                <div className="main__left" >
                    <div className="videos__group overflow-hidden hover:overflow-auto">
                        <div id="video-scroll" className="mob-vid overflow-hidden hover:overflow-auto"></div>
                        <div id="video-grid" className=""></div>
                    </div>
                    <div className="options conf-footer">
                        <div className="room-options-bar">
                            {
                                <CameraButton peerInstance={peerInstance.current} />
                            }
                            {
                                <MicButton peerInstance={peerInstance.current} />
                            }
                            <div id="showChat" className="options__button">
                                <img src="/Iconconferancems.png"/>
                            </div>
                            {
                                PURPOSE === "Group" &&
                                <MeetingParticipants room={currentRoom} />
                            }
                            {PURPOSE === "Group" && userStore.username === currentRoom.username && <div className="options__button">
                                <Invite />
                            </div>}
                            <div className="options__button">
                                {
                                    <img src="/Iconscreen.png" onClick={toggleScreenShare} />

                                }
                            </div>
                            <div
                                className="options__button "
                                onClick={() => handleLeaveCall()}
                            >
                                <img src='/holdconf.png'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__right conf-chat">
                    <MeetingChat room_id={ROOM_ID} />
                </div>
            </Split>
        </>
    )
}
)

export default Room;