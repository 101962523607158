export const langValidate = {
    "arm": {
        tr_passLengthValidate: "Գաղտնաբառր պետք է ունենա մինիմում 8 սիմվոլ",
        tr_minAndMaxLength: "Օգտանունը պետք է լինի մինիմում 3 սիմվոլ և մաքսիմում 18 սիմվոլ"     
    },
    "eng": {   
        tr_passLengthValidate: "Password must have at least 8 characters",
        tr_minAndMaxLength: "Username must be at least 3 characters and a maximum of 18 characters"     
    },
    "fr": {  
        tr_passLengthValidate: "",
        tr_minAndMaxLength: ""      
    },
    "arb": {  
        tr_passLengthValidate: "",
        tr_minAndMaxLength: ""      
    },
}