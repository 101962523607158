import React, {memo, useContext} from "react"
import { langContext } from "../../App";
import "./Toggle.css"
import { langToggle } from "./toggleTranslate";

const Toggle = ({defaultChecked, onHandelChange}) => {
    const lang = useContext(langContext);
    let showBySelected = langToggle[lang]

    return (
        <div className=" ">
            <h4>{showBySelected.tr_createMeeting}</h4>
            <label className="switch ">
                <input type="checkbox" id="create_meeting" name="create_meeting" onChange={(e) => onHandelChange(e)}
                       className="appearance-none checked:bg-blue-500" checked={defaultChecked}/>
                <span className="slider  round"></span>
            </label>
        </div>)


}

export default Toggle