import React, {useEffect, useState} from "react";

export const MeetingTime = ({start_date}) => {

    const [time, setTime] = useState()


    useEffect(() => {
        let totalSeconds = Math.floor((new Date() - new Date(start_date)) / 1000);
        const timer = setInterval(function () {
            ++totalSeconds
            let hour = Math.floor(totalSeconds / 3600);
            let minute = Math.floor((totalSeconds - hour * 3600) / 60);
            let seconds = totalSeconds - (hour * 3600 + minute * 60);
            if (hour < 10)
                hour = "0" + hour;
            if (minute < 10)
                minute = "0" + minute;
            if (seconds < 10)
                seconds = "0" + seconds;
                setTime(hour + ":" + minute + ":" + seconds)

        }, 1000);

        return () => clearInterval(timer)
    }, [start_date])

    return (
        <>
        {
                        time && <p className='' style={{"font-size": "14px"}}>
                            {time}
                        </p>
                    }
        </>
    )
}