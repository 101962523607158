import React, {useContext, useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {socket} from "../const/socket"
import UserElement from "./user/UserElement";
import LoadingSpinner from "./Loading"
import SOCKET from "../const/socketNames"
import CustomModal from "./modal/CustomModal";
import {useStore} from "../store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import emailClose from "../assets/images/email.png";
import emailOpen from "../assets/images/email_open.png";
import User from "../states/User";
import {popUp} from "../utils/helpers";
import { langContext } from "../App";
import { langSideNav } from "./sideNavTranslate";
import customerImage from "../assets/images/customer-service.png";
import "../pages/homepage.css"


const SideNav = observer(({searchInput, setSearchInput, groupChatOpened, setCloseMessagesModal, closeMessagesModal}) => {
    const lang = useContext(langContext);
    let showBySelected = langSideNav[lang]

    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [sort, setSort] = useState(false)
    const [selectedUser, setSelectedUser] = useState("")
    const [wainAnswerCall, setWaitAnswerCall] = useState(false)
    const [callStatus,setCallStatus] = useState(true)


    const {userStore, usersStore, videoRoomStore, chatStore} = useStore()

    useEffect(() => {
        const timer = setInterval(() => {
            usersStore.getUsers();
        }, 300000)

        return () => clearInterval(timer)
    }, [])

    useEffect(() => setSort(false),[groupChatOpened])

    useEffect(() => {
        socket.on(SOCKET.CHAT_UNVIEWED_ROOMS, (data) => {
            if (data.role === "Admin") {
                chatStore.setAdminChat(true)
            } else {
                usersStore.setUnviewedChats(data.username)
            }
        })
        socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
            usersStore.removeUnviewedChats(userStore.username)
        })
        socket.on(SOCKET.USERS_ONLINE_LIST, (users) => usersStore.setOnlineUsersList(users))
        // window.addEventListener("beforeunload", () => {
        //     if(wainAnswerCall) {
        //         socket.emit(SOCKET.SHUT_DOWN_CALL, selectedUser, User.username, true)
        //     }
        // })
        return () => {
            socket.off(SOCKET.CHAT_UNVIEWED_ROOMS)
            socket.off(SOCKET.CHAT_REFRESH_ROOMS)
            socket.off(SOCKET.USERS_ONLINE_LIST)

        }

    }, [])

    function closeModal() {
        setCallStatus(true)
        setIsOpen(false);
        setWaitAnswerCall(false)
        const isCreateMessage = true
        socket.emit(SOCKET.SHUT_DOWN_CALL, selectedUser, User.username, isCreateMessage, videoRoomStore.roomId)
    }


    useEffect(() => {
        let timer = null
        if (wainAnswerCall) {
            timer = setTimeout(() => {
                closeModal()
            }, 60000)
        }
        if(!wainAnswerCall){
            clearTimeout(timer)
        }
        return () => clearTimeout(timer)

    }, [wainAnswerCall])

    useEffect(() => {
        socket.on(SOCKET.PRIVATE_CALL_ANSWER_APPROVE, () => {
            const joinCall = document.getElementById("joinCall");
            joinCall.removeAttribute("disabled");
            setCallStatus(false)
            joinCall.addEventListener("click", joinCallHandle);
            const audio = document.getElementById("audioCall")
            audio.pause()
        })

        socket.on(SOCKET.PRIVATE_CALL_ANSWER_CANCEL, () => {
            setIsOpen(false);
        })

        return () => {
            socket.off(SOCKET.PRIVATE_CALL_ANSWER_APPROVE)
            socket.off(SOCKET.PRIVATE_CALL_ANSWER_CANCEL)
        }

    }, [])


    useEffect(() => {
        usersStore.getUnreadMessages()
        userStore.role && setLoading(false)
    }, [])

    const users = usersStore.usersList.map((user) =>
        (<UserElement        
            key={user._id}
            user={user}
            status={usersStore.onlineUsers.includes(user.username) && userStore.username}
            setWaitAnswerCall={setWaitAnswerCall}
            setSelectedUser={setSelectedUser}
            setIsOpen={setIsOpen} 
            closeMessagesModal={closeMessagesModal}      
            setCloseMessagesModal={setCloseMessagesModal}     
        />))

    const getUsersBySearch = (e) => {
        usersStore.getUsers(e.target.value)
    }


    const getUnreadMessage = () => {
        if (!sort) {
            usersStore.viewedNewMessages()
            setSort(!sort)
        } else {
            usersStore.getUsers('')
            setSort(!sort)
        }
    }

    const joinCallHandle = () => {
        setIsOpen(false)
        setWaitAnswerCall(false)
        setCallStatus(true)
        if (videoRoomStore.roomId) {
            popUp(`/video-room/${videoRoomStore.roomId}?status=caller`)
        }
    }

    return (
      <div className="user-sidebar">
        <div  style={{"display":"flex", "width":"100%"}} >
            <div className="search-input-div">
              <div className="search-logo-back ">
                <img src="/search.png" className="search-logo"/>
              </div>
              <input
                onChange={(e) => {getUsersBySearch(e); setSearchInput(e.target.value)}}
                className="search-input"
                type="search"
                value={searchInput}
                placeholder={showBySelected.tr_search}
              />          
            </div>
            { usersStore.unviewedChats.length > 0 ? (!sort ? <img src="/msclwith.png" style={{"width":"40px", "height":"40px","cursor":"pointer"}} onClick={getUnreadMessage}/>
                          : <img src="/msopwith.png" style={{"width":"40px", "height":"40px","cursor":"pointer"}} onClick={getUnreadMessage}/>) :
                    (!sort ? <img src="/msclwitout.png" style={{"width":"40px", "height":"40px","cursor":"pointer"}} onClick={getUnreadMessage}/>
                          : <img src="/msopwithout.png" style={{"width":"40px", "height":"40px","cursor":"pointer"}} onClick={getUnreadMessage}/>)
            }
        </div>
        <ul className="users-list-scroll" >
          {loading ? (
            <div className="flex justify-center items-center mt-10">
              <LoadingSpinner />
            </div>
          ) : (
            users
          )}
        </ul>
       <div className="admin-chat-div">
        <div className="admin-div" onClick={() => {socket.emit(SOCKET.CHAT_CREATE, {
                username: userStore.username,
                guestUsername: "Admin",
              });
              chatStore.setAdminChat(false);
              socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {
                name: userStore.username,
                caller: "Admin",
              });
            }}>
          <img src="/Iconadmin.png" className="admin-icon"
            
            alt="support"/>
            <p className="fontstyles titeles" style={{"margin":"0 10px"}}>Support</p>
            {chatStore.adminChat && <span className="bg-red-400 h-3 w-3 rounded-full" />}
        </div>
        </div>
        {wainAnswerCall && (
          <CustomModal  isClose={closeModal} isOpen={isOpen}>
            <div className="ans-waiting-div">
              <p className="ans-waiting-status">{callStatus
                      ? showBySelected.tr_waitingForResponse
                      : showBySelected.tr_connect}</p>
              <div className="ans-waiting-btns"> 
                <button
                  type="button"
                  onClick={() => closeModal(true)}
                  className="answer-waiting-reject"
                >
                  <img src="/Iconrejectcall.png" />
                  {showBySelected.tr_reject}
                </button>
                <input
                  className="ans-waiting disabled:cursor-default"
                  style={callStatus ? {"display" : "none"} : {}}
                  id="joinCall"
                  type="button"
                  value={showBySelected.tr_connect}
                  disabled
                />
               
              </div>
              <audio
                controls
                autoPlay
                style={{ display: "none" }}
                id="audioCall"
              >
                <source src="/gudok.mp3" type="audio/mp3" />
              </audio>

              
            </div>
          </CustomModal>
        )}
      </div>
    );
})

export default SideNav