import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { langContext } from "../App";
import ChangePassword from "../components/ChangePassword";
import CheckDevices from "../components/checkDevices/checkDevicesStatus";
import LangSection from "../components/Lang/LangSection";
import UserForm from "../components/user/UserForm";
import "./profile.css"
import { langProfile } from "./profileTranslate";

const Profile = ({navbar, change, setNavbar}) => {

    const [section, setSection] = useState("profile")
    let lang = useContext(langContext)
    let showBySelected = langProfile[lang]

    const setProfile = () => setSection("profile")
    const setVideoCheck = () => setSection("videoCheck")
    const setPassword = () => setSection("password")
    const setLang = () => setSection("language")
    return (
        <>
        <div className="profile-page" style={{"height": window.innerHeight - 60}}>
            <div className="title-div"><h1>{showBySelected.tr_setting}</h1><img src="/shapeclose.png" onClick={() => setNavbar("")}/></div>
            <div className="info-page">
                <div className="choose-part" >
                     <div className={section === "profile" ? "choose-section active-section" : "choose-section"} onClick={setProfile} >
                        <img className="section-icon" src={section === "profile" ? "/userch.png" : "/usericon.png"}/>
                        <p className="pc-head-sections">{showBySelected.tr_profile}</p>
                     </div>
                     <div className={section === "password" ? "choose-section active-section" : "choose-section"} onClick={setPassword} >
                        <img className="section-icon" src={section === "password" ? "/lochch.png" :"/lockicon.png"}/>
                        <p className="pc-head-sections">{showBySelected.tr_password}</p>
                     </div>
                     <div className={section === "videoCheck" ? "choose-section active-section" : "choose-section"} onClick={setVideoCheck}>
                        <img className="section-icon" src={section === "videoCheck" ? "/videoch.png" :"/recorder.png"}/>
                        <p className="pc-head-sections"> {showBySelected.tr_audioVideo}</p>
                     </div>
                     <div className={section === "language" ? "choose-section active-section" : "choose-section"} onClick={setLang}>
                        <img className="section-icon" src={section === "language" ? "/languagech.png" :"/language.png"}/>
                        <p className="pc-head-sections">{showBySelected.tr_language}</p>
                     </div>
                </div>
                <div className="info-part">
                    {section === "profile" ? (<UserForm navbar={navbar}/>): section === "password" ? <ChangePassword/> : section === "videoCheck" ? <CheckDevices/> : <LangSection change={change}/>}
                </div>
            </div>
        </div>     
           
        </>
    );
};

export default Profile;
