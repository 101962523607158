import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";

const InviteButton = () => {

    const openPrompt = () => {
        const url = window.location.href.split("/video-room/")
        const roomId = url[1].split("?")[0]
        prompt(
            "Copy this link and send it to people you want to meet with",
            `${url[0]}/video-guest?secret=${123456789}&room_id=${roomId}`
        );
    }

    return (
        <div id="inviteButton" className="options__button" onClick={openPrompt}>
            <img src="/adduser.png" />
    </div>
    )
}

export default InviteButton