export const langAlert = {
    "arm": {
        tr_succeeded: "Հաջողված է",
        tr_error: "Սխալմունք"
    },
    "eng": {
        tr_succeeded: "Succeeded",
        tr_error: "Error"
    },
    "fr": {
        tr_succeeded: "",
        tr_error: ""
    },
    "arb": {
        tr_succeeded: "",
        tr_error: ""
    },
}