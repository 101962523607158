import React, {useContext, useEffect, useState} from 'react';
import VideoRoom from "../../../states/VideoRoom";
import {observer} from "mobx-react-lite";
import {useStore} from "../../../store";
import {options} from "../../../utils/helpers";
import FilterUserLogs from "../components/FilterUserLogs";
import { langContext } from '../../../App';
import { langLoggingList } from './loggingListTranslate';

let a = 0
const LoggingList = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langLoggingList[lang]

    console.warn(`Logging List count ${++a}`)
    const {adminStore} = useStore()

    // useEffect(() => {
    //     adminStore.LogsByUser(adminStore.active_username).then(res => setLogs(res.data))
    // }, [adminStore.active_username])

    return (
        <div className="flex flex-col border-r border-slate-300  basis-3/12">
            <div className='border-b p-3'>
                <span className='font-bold'>{showBySelected.tr_loginLogout}</span>
            </div>
            <FilterUserLogs/>
            <div className='overflow-y-scroll'>
                <table className="border-collapse border border-r-0 border-slate-400 w-full">
                    <thead>
                    <tr>
                        <th className="border border-slate-300 ">{showBySelected.tr_login}</th>
                        <th className="border border-r-0 border-slate-300 ">{showBySelected.tr_logout}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {adminStore.logs.map((el) => (
                        <tr key={el._id}>
                            <td className='border border-slate-300 border-r-0'>{new Date(el.created_at).toLocaleDateString('en-GB', options)}</td>
                            <td className='border border-slate-300 border-r-0'>{new Date(el.logout).toLocaleDateString('en-GB', options)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
});

export default LoggingList;