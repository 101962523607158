import {makeObservable, action, observable, computed, runInAction} from "mobx";
import {axiosInstance} from "../../const/axiosInstance";
import AlertComponent from "../../utils/Alert";
import {ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS} from "../../const/Constants";
import typeOf from "validator/es/lib/util/typeOf";
import VideoRoom from "../VideoRoom";

class Users {
    users = []
    content = "meeting"
    logs = []
    active_username = ""
    messages_logs = []
    pin_codes = []

    constructor() {
        makeObservable(this, {
            users: observable.ref,
            usersList: computed,
            changeContent: action,
            changeSetting: action,
            createPinCode: action,
            content: observable.ref,
            logs: observable,
            messages_logs: observable,
            active_username: observable,
            pin_codes: observable
        })
    }

    async getUsers(search) {
        try {
            const {data: {data: response}} = await axiosInstance.get(`/admin/users`, {
                params: {
                    search: search ? search : ""
                }
            })
            runInAction(() => {
                this.users = response.filter(el => el.username !== this.username)
            })


        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
            this.username = null
            localStorage.removeItem('username')
        }
    }

    async deleteUserAccount(userId) {
        try {
            const {data} = await axiosInstance.delete(`/admin/users/${userId}`)
            return data

        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.response.data.message)
        }
    }

    async deleteUserPassword({password}) {
        try {
            await axiosInstance.post(`/admin/forgot-password`, {username: this.active_username, password})
        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.response.data.message)
        }
    }

    get usersList() {
        return this.users
    }

    async LogsByUser(startDate, endDate) {
        try {
            const result = await axiosInstance.get(`admin/user-logs?username=${this.active_username}&start_date=${startDate?.toISOString()}&end_date=${endDate?.toISOString()}`)
            this.logs = result.data.data
            return result.data
        } catch (err) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, err.response.data.message)
        }
    }

    async changeSetting(create_room) {
        try {
            const {data} = await axiosInstance.post(`/admin/user/settings`, {
                username: this.active_username,
                create_room
            })
            if (data.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_SUCCESS, data.message)
            } else {
                AlertComponent(ALERT_MESSAGE_TYPE_DANGER, data.message)
            }
        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.response.data.message)
        }
    }

    async createPinCode(pin_code, used_limit, usedUsername) {
        try {
            const {data} = await axiosInstance.post(`/admin/pin_code`, {pin_code, used_limit, used_username: usedUsername})
            if (data.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_SUCCESS, data.message)
                await this.getAllPinCodes()
            } else {
                AlertComponent(ALERT_MESSAGE_TYPE_DANGER, data.message)
            }
        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.response.data.message)
        }
    }

    async getMessagesLogs(start_date, end_date, type, userna) {
        try {
            const {data} = await axiosInstance.post(`/admin/messages`, {
                start_date: start_date && start_date.toISOString(),
                end_date: end_date && end_date.toISOString(),
                type,
                username: this.active_username
            })
            this.messages_logs = data.data
        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.message)
        }
    }

    async deleteMeetings(end_date) {
        try {
            const {data} = await axiosInstance.delete(`/admin/meetings?end_date=${end_date && end_date.toISOString()}`)

            const status = data.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER

            AlertComponent(status, data.message)
        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.message)
        }
    }

    async deleteMessageLogs(end_date) {
        try {
            const {data} = await axiosInstance.delete(`/admin/messages?end_date=${end_date && end_date.toISOString()}`)
            const status = data.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER

            AlertComponent(status, data.message)
        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.message)
        }
    }

    async deleteUserLogs(end_date) {
        try {
            const {data} = await axiosInstance.delete(`/admin/user-logs?end_date=${end_date && end_date.toISOString()}` )
            const status = data.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
            AlertComponent(status, data.message)

        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.message)
        }
    }

    async declineMeeting(id) {
        try {
            const {data} = await axiosInstance.get(`/admin/meetings/${id}` )
            const status = data.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
            if(data.status){
                VideoRoom.changeMeetingsType(id)
            }
            AlertComponent(status, data.message)

        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.message)
        }
    }

    async getAllPinCodes() {
        try{
            const {data} = await axiosInstance.get(`/admin/pin_code` )
            this.pin_codes = data.data



        }catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.message)
        }
    }



    changeContent(value, username) {
        this.messages_logs = []
        this.active_username = username
        this.content = value
    }
}

export default new Users()