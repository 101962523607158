import React, {useCallback, useContext, useRef, useState} from 'react';
import DateTimePicker from 'react-datetime-picker';
import SelectOption from "../../../components/SelectOption";
import {useStore} from "../../../store";
import {observer} from "mobx-react";
import DeleteContent from "./DeleteContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faTimes} from "@fortawesome/free-solid-svg-icons";
import { langContext } from '../../../App';
import { langFilterMeeting } from './filterMeetingTranslate';


const FilterMeeting = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langFilterMeeting[lang]

    const [startDate, setStartDate] = useState();
    const inputRef = useRef()
    const [endDate, setEndDate] = useState();
    const [selected, setSelected] = useState([])
    const [meetingStatus, setMeetingStatus]= useState([])
    const {videoRoomStore, adminStore} = useStore()
    let options = []
    Object.keys(adminStore.users).forEach((index) => {
            options = [...options,{value:adminStore.users[index].username,label: adminStore.users[index].name || adminStore.users[index].last_name ? adminStore.users[index].name + " " + adminStore.users[index].last_name : adminStore.users[index].username }]
        });
    const onChange = useCallback( (selectedOptions) => setSelected(selectedOptions), []);

    const filterMeeting  = (e) => {
        e.preventDefault()
        const data = {
            meeting_name: inputRef.current.value,
            start_date: startDate,
            end_date: endDate,
            invited_users: selected.map(el => el.value),
            meeting_status: meetingStatus
        }

        videoRoomStore.filterMeetingList(data)
    }

    const statusChange = (e) => {
        if(e.target.checked) {
            if(!meetingStatus.includes(e.target.name)) {
                setMeetingStatus([...meetingStatus, e.target.name])
            }
        }else {
            if(meetingStatus.includes(e.target.name)) {
                setMeetingStatus(meetingStatus.filter(el => el !== e.target.name))
            }
        }
    }



    return (
        <>

            <form className="flex flex-wrap items-center p-2" onSubmit={filterMeeting}>
                <div className="relative z-0 w-full flex-1 group m-2 mb-4">
                    <input type='text' name="conference" id="conference" ref={inputRef}
                           className="block w-full py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                           placeholder=" "/>
                    <label htmlFor="conference"
                           className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{showBySelected.tr_conference}</label>
                </div>


                <div className="relative z-0 w-full flex-1 group  ">
                    <DateTimePicker onChange={setStartDate} value={startDate} locale="hy-AM" />
                    <h3>{showBySelected.tr_till}</h3>
                    <DateTimePicker onChange={setEndDate} value={endDate} locale="hy-AM" />

                </div>
                <div className="relative z-0 w-full flex-1 group m-2 mb-4">
                    <SelectOption
                        isMulti
                        value={selected}
                        options={options}
                        onChange={onChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={showBySelected.tr_selectUsers}
                    />
                    <div className="flex justify-around mt-2">
                        <div  className='gap-2 grid grid-cols-2'>
                            <FontAwesomeIcon className="fa fa-check text-green-500" icon={faCheck} aria-hidden="true"/>
                            <input type="checkbox" name="approved" onChange={statusChange }/>
                        </div>
                        <div className='gap-2 grid grid-cols-2'>
                            <FontAwesomeIcon className="fas fa-times text-red-500" icon={faTimes}/>
                            <input type="checkbox" name="declined" onChange={statusChange }/>

                        </div>
                        <div className='gap-2 grid grid-cols-2'>
                            <FontAwesomeIcon icon={faClock} className="far fa-clock"/>
                            <input type="checkbox" name="pending" onChange={statusChange }/>

                        </div>
                    </div>
                </div>
                <div className='flex basis-full justify-center items-center w-full'>
                    <button type="submit" className="bg-green-300 h-5 w-36 rounded-lg m-2  text-sm cursor-pointer ">{showBySelected.tr_search}</button>
                    <DeleteContent status="meeting"/>
                </div>
            </form>
        </>

    );
});

export default FilterMeeting;