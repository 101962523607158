export const langChangeMeetingCreatePermision = {
    "arm": {
        tr_permissionToCreate: "Կոնֆերենս ստեղծելու թույլատվություն"
    },
    "eng": {
        tr_permissionToCreate: "Permission to create a conference"
    },
    "fr": {
        tr_permissionToCreate: "Autorisation pour créer une conférence"
    },
    "arb": {
        tr_permissionToCreate: "تصريح بإنشاء مكالمة جماعية"
    }
}