export const langMeetingList = {
    "arm": {
        tr_meetingList: "Միտինգների ցուցակ",
        tr_name: "Անվանում",
        tr_participant: "Մասնակից",
        tr_time: "Ժամ",
        tr_status: "Վիճակ",
        tr_block: "Արգելափակել"
    },
    "eng": {
        tr_meetingList: "Meeting list",
        tr_name: "Name",
        tr_participant: "Participant",
        tr_time: "Time",
        tr_status: "Status",
        tr_block: "Block"
    },
    "fr": {
        tr_meetingList: "Liste de réunions",
        tr_name: "Nom",
        tr_participant: "Participant",
        tr_time: "Heure",
        tr_status: "Statut",
        tr_block: "Bloquer"
    },
    "arb": {
        tr_meetingList: "قائمة الاجتماعات",
        tr_name: "التسمية",
        tr_participant: "مشارك",
        tr_time: "الساعة",
        tr_status: "الحالة",
        tr_block: "حظر"
    }
}