
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faPlay, faTimes,faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import { langMeetingList } from "../container/meetingsListTranslate";


export const getRoomIconByStatus = (status) => {

    let language = localStorage.getItem("langFromStorage")
    let showTranslated = langMeetingList[language]
    switch (status) {
        case "approved":
            return showTranslated.tr_established
        case "started":
            return showTranslated.tr_started
        case "declined":
            return showTranslated.tr_cancelled
        case "warning":
            return showTranslated.tr_late
        default:
            return showTranslated.tr_overdue
    }
}

export const options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'};

export const getClassByStatus = (status) => {
    switch (status) {
        case "approved":
            return 'bggreen font-status '
        case "declined":
            return 'bggrey font-declined-status '
        case "warning":
            return 'bgred font-status '
        case "started":
                return 'bgblue font-status'
        default:
            return 'bgyellow font-status '
    }
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const getDateByMonthAndHour = (date, hour) => {
    return date.substr(0, 10) + "T" + hour + ":00.000Z"
}

export const popUp = (url) => {
     const popup = window.open(url, "_blank");
     return popup !== null && typeof popup !== 'undefined'
}