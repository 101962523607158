export const langPinCadeList = {
    "arm": {
        tr_pinCodeList: "Փին կոդերի ցուցակ",
        tr_name: "Անվանում",
        tr_numberOfUsers: "Օգտվողների քանակություն",
        tr_userLimit: "Օգտվողների սահման",
        tr_addressee: "Ում է տրվել"
    },
    "eng": {
        tr_pinCodeList: "Pin code list",
        tr_name: "Name",
        tr_numberOfUsers: "Number of users",
        tr_userLimit: "User limit",
        tr_addressee: "Addressee"
    },
    "fr": {
        tr_pinCodeList: "Liste de Codes PIN",
        tr_name: "Nom",
        tr_numberOfUsers: "Nombre d’utilisateurs",
        tr_userLimit: "Limite des utilisateurs",
        tr_addressee: "Destinataire"
    },
    "arb": {
        tr_pinCodeList: "قائمة كلمات المرور",
        tr_name: "تسمية",
        tr_numberOfUsers: "عدد المستخدمين",
        tr_userLimit: "تحديد عدد المستخدمين",
        tr_addressee: "تمّ المنح لـ"
    }
}