import React, {useState, useContext, useEffect} from "react";
import VideoRoom from "../states/VideoRoom";
import Modal from "./modal/CustomModal"
import { SocketContext } from "../const/socket"
import SOCKET from "../const/socketNames"
import User from "../states/User";
import {useStore} from "../store";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faUsers, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {observer} from "mobx-react-lite";

const MeetingParticipants = observer(({ room }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [users, setUsers] = useState([])
    const {videoRoomStore , usersStore} = useStore()


    const onCLose = () => {
        setIsOpen(false)
    }

    const socket = useContext(SocketContext)

    const removeParticipantFromMeeting = (username) => {
        socket.emit(SOCKET.DELETE_USER_FROM_MEETING, username)
        setUsers(users.filter(el => el.username !== username))
    }

    const handleClick = async () => {
        const participants = await VideoRoom.getMeetingAllParticiapantsById(room._id)
        setUsers(participants.filter(item => item.username !== User.username))
        setIsOpen(true)
    }

    useEffect(() => {
        usersStore.getUsers()
    }, [])

    const usersList = users.map(user => (
        <>
            <li key={user.username} className="p-3 m-2 border border-gray-400 hover:bg-gray-200 rounded">{usersStore.users.find(el => el.username === user.username) ? usersStore.fullName(usersStore.users.find(el => el.username === user.username)) : user.username}

                {User.username === room.username && <span
                    className="text-red-400 ml-2 cursor-pointer"
                    onClick={() => removeParticipantFromMeeting(user.username)}>
                    <FontAwesomeIcon icon={faBan} className="fas fa-ban"></FontAwesomeIcon></span>}
            </li>
        </>
    ))

    return (
        <div>
            <div className="options__button" onClick={handleClick}>
                <img src="/participants.png" />
                {videoRoomStore.participants_count}
            </div>
            <Modal isOpen={isOpen} isClose={onCLose} >

                <div className={'w-full h-96 my-2 participants-div'}>
                    <div className='flex justify-end' style={{"margin" : "10px 10px"}}>
                        <button type="button" onClick={() => onCLose()}
                            className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                            <FontAwesomeIcon icon={faWindowClose} className="fa fa-window-close" aria-hidden="true" />
                        </button>
                    </div>
                    <ul className='justify-center w-96'>
                        {usersList}
                    </ul>
                </div>
            </Modal>
        </div>
    )
})

export default MeetingParticipants