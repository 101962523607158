import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFileUpload} from "@fortawesome/free-solid-svg-icons";
import { Children } from "react";

const FileUpload = ({ setField ,setSize, uploadFile, purpose , classNames, children, navbar}) => {

    const handleChange = async (e) => {
            const data = new FormData()
            data.append('file', e.target.files[0])
            data.append("filename",e.target.files[0]?.name )
            purpose && data.append("purpose", "meeting")
            const {path, size} = await uploadFile(data)
            setField(path)
            if(setSize){
                setSize(size)
            }
    };


    return (<div className={navbar === "profile" ? "upload-btn" : ""}>

                    <label htmlFor="file-input" className={classNames}>
                        {children}
                    </label>
                    <input   className="hidden" id="file-input" type="file" name="file" onChange={(e) => handleChange(e)} />


    </div>)
}

export default FileUpload