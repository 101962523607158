import {  useRef, useState, useContext } from "react";
import VideoRoom from "../../states/VideoRoom";
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import SelectOption from "../SelectOption";
import {useStore} from "../../store";
import { useCallback } from "react";
import { SocketContext } from '../../const/socket'
import SOCKET from "../../const/socketNames"
import {getDateByMonthAndHour} from "../../utils/helpers"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import { langContext } from "../../App";
import { langCreateRoom } from "./createRoomTranslate";
import "./mkroom.css"


function Lobby({setIsOpen}) {
    const lang = useContext(langContext);
    let showBySelected = langCreateRoom[lang]


    const [roomName, setRoomName] = useState("")
    const [date, setDate] = useState(new Date())
    const [hour, setHour] = useState("")
    const [selected, setSelected] = useState([])
    const buttonDisabledRef = useRef()
    const users = JSON.parse(localStorage.getItem("users"))
    const [options] = useState(function () {
        var res = [];
        Object.keys(users).forEach((index) => {
            res = [...res,{value:users[index].username,label: users[index].name || users[index].last_name ? users[index].name + " " + users[index].last_name : users[index].username }]
        });
        return res;
    })
    const socket = useContext(SocketContext)
    const onChange = useCallback( (selectedOptions) => setSelected(selectedOptions), []);
    const countRef = useRef("")


    const chatCloseHandleClick = () => {
        setIsOpen(false)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        buttonDisabledRef.current.setAttribute('disabled', true)
        const invited_users = selected.map(el=> el.value)
        const newIsoDate = new Date(date.toISOString());
        newIsoDate.setHours(newIsoDate.getHours() + 4);
        const isoDate = new Date(getDateByMonthAndHour(newIsoDate.toISOString(), hour))
        isoDate.setHours(isoDate.getHours() - 4);
        await VideoRoom.setVideoRoomByName({
            name: e.target.room.value,
            count: countRef.current.value,
            start_date: isoDate.toISOString(),
            purpose: "Group",invited_users,
        })
        await VideoRoom.getMyVideoMeetings()

        socket.emit(SOCKET.MEETING_CREATE, invited_users)

        chatCloseHandleClick()
    }

    const handleRoomNameChange = (event) => {
        setRoomName(event.target.value)
    }

    return (
        <form onSubmit={handleSubmit} className="mk-sizes mk-font" style={{maxWidth: '1000px'}}>
            <div className="mk-main-div">
                <div className="mk-header">
                    <h2 className="mk-font-17">{showBySelected.tr_createNewMeeting}</h2>
                    <button type="button" onClick={() => chatCloseHandleClick()}
                            className="">
                        <img src="/shapeclose.png" />
                    </button>
                </div>
               <div className=''>
                   <div className='mk-16'>
                       <div>
                           <label htmlFor="room" className='mk-font-17'>{showBySelected.tr_meetingTitle}</label>
                           <input
                               type="text"
                               placeholder={showBySelected.tr_meetingTitle}
                               id="room"
                               name="room"
                               maxLength={30}
                               className="mk-input"
                               value={roomName}
                               onChange={(e) => handleRoomNameChange(e)}
                               required
                           />
                       </div>
                       <div style={{"display":"none"}}>
                           <label htmlFor="room" className='block'>{showBySelected.tr_theNumberOfParticipants}</label>
                           <input
                               type="number"
                               id="participant_count"
                               name="count"
                               disabled
                               ref={countRef}
                               value={selected.length}
                               className="border-2 w-full mb-2"
                               required
                           />
                       </div>
                       
                       <div className='mk-tb-16'>
                           <p className="mk-font-17">{showBySelected.tr_participants}</p>
                           <SelectOption
                               isMulti
                               value={selected}
                               options={options}
                               onChange={onChange}
                               className="basic-multi-select mk-select-input"
                               classNamePrefix="select"
                               placeholder={showBySelected.tr_selectUsers}
                           />
                       </div>
                   </div>
                   <div className="mk-16">
                       <p className="mk-font-17">{showBySelected.tr_dayAndTime}</p>
                       <Calendar className="mk-calendar" value={date} locale="hy-AM" onChange={setDate} minDate={new Date()} />
                       <div className="mk-time">
                            <label htmlFor="appt">{showBySelected.tr_selectTheTime}։ </label>
                            <input type="time" id="appt" name="appt" className="cursor-pointer mk-font-17" required value={hour} onChange={(e) => setHour(e.target.value)} />
                       </div>
                   </div>
               </div>
               <div className="mk-btn-pos">
                <button className="mk-btn" ref={buttonDisabledRef} type="submit">{showBySelected.tr_accept}</button>
               </div>
            </div>
        </form>
    );
}

export default Lobby;