export const langSideNav = {
    "arm": {
        tr_users: "Օգտվողներ",
        tr_search: "Որոնում"
    },
    "eng": {
        tr_users: "Users",
        tr_search: "Search"
    },
    "fr": {
        tr_users: "Utilisateurs",
        tr_search: "Recherche"
    },
    "arb": {
        tr_users: "المستخدمون",
        tr_search: "بحث"
    }
}