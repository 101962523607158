import React, { useState } from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrophone, faMicrophoneSlash} from "@fortawesome/free-solid-svg-icons";

const MicButton = ({peerInstance})  => {
    const [mute, setMute] = useState(true)

    const toggleMutButton = () => {
        peerInstance.toggleMic(mute)
        setMute(!mute)
    }
    return (
        <div id="muteButton" className={`options__button `} onClick={toggleMutButton}>
            {
                mute ? (
                    <img src="/Iconmicrophone.png"/>
                    )
                    :
                    <img src="/IconmicraphoneMute.png"/>
            }
        </div>
    )
}

export default MicButton