export const langDeleteByDate = {
    "arm": {
        tr_confirm: "Հաստատել",
        tr_refuse: "Մերժել"
    },
    "eng": {
        tr_confirm: "Confirm",
        tr_refuse: "Refuse / deny"
    },
    "fr": {
        tr_confirm: "Approuver",
        tr_refuse: "Rejeter"
    },
    "arb": {
        tr_confirm: "تأكيد",
        tr_refuse: "رفض"
    }
}