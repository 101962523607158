import {useContext, useEffect} from "react";
import { langContext } from "../../App";
import { langAnswerCall } from "./answerCallTranslate";
import "./answer.css"
import { useStore } from "../../store";
import userAvatar from "../../assets/images/user_avatar.png"
import { BACKEND_URL } from "../../const/Constants";


export const AnswerCall = ({avatarImg, closeModal, name, answerCall}) => {
    const lang = useContext(langContext);
    let showBySelected = langAnswerCall[lang]

    const {usersStore} = useStore()

    useEffect(() => {
        if(name) {
            const audio = document.getElementById("audioAnswer")
            audio.muted=false
            audio.play()
        }
        }, [name])

    return (
        <div className="ans-main">
            <div className="ans-sizes">
                <div className="ans-caller">
                    <img src={usersStore.users.find(el => el.username === name) ? `${BACKEND_URL}${usersStore.users.find(el => el.username === name).image}` : userAvatar} />
                    <h1>{name}</h1>                    
                </div>
                <p className="ans-calling">{showBySelected.tr_callingYou}</p>

                <audio controls muted style={{display: "none"}} id="audioAnswer" >
                    <source src="/callme.mp3"  type="audio/mp3"/>
                </audio>

                <div className="ans-btns">
                    <button className="ans-reject"
                            onClick={closeModal}>
                        <img src="/Iconrejectcall.png" />        
                        {showBySelected.tr_reject}
                    </button>
                    <button
                        className="ans-accept"
                        onClick={answerCall}>
                        <img src="/Iconvideoaccept.png" />
                        {showBySelected.tr_answer}
                    </button>
                </div>
            </div>
        </div>
    )
}