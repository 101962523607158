import {action, computed, makeObservable, observable, runInAction} from "mobx"
import AlertComponent from "../utils/Alert"
import {axiosInstance} from "../const/axiosInstance"
import {ALERT_MESSAGE_TYPE_DANGER} from "../const/Constants"


class VideoRoom {
    roomId = ""
    participants_count=1
    socket_id
    all_meetings = []
    my_meetings = []
    invite_meetings = []

    constructor() {
        makeObservable(this, {
            setVideoRoomByName: action,
            my_meetings: observable,
            invite_meetings: observable,
            all_meetings:observable,
            allMeetings:computed,
            participants_count: observable,
            setParticipantsCount: action
        })
    }

    async setVideoRoomByName({name, count, start_date, purpose, invited_users}) {
        try {
            const {data: {data}} = await axiosInstance.post('/devices/meetings', {
                room_name: name,
                count,
                start_date,
                purpose,
                invited_users
            })
            this.roomId = data._id
            this.participants_max_count = data.participants_max_count
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async setPersonalRoom({answer_username}) {
        try {
            const {data: {data}} = await axiosInstance.post('/devices/meetings/personal', {answer_username})
            this.roomId = data._id
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async getVideoRoomByName(purpose) {
        try {
            const {data: {data}} = await axiosInstance.get(`/devices/meetings?roomId=${this.roomId}&purpose=${purpose}`)
            this.socket_id = data.socket_id
            this.roomId = data._id
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async getVideoRoomByRoomId(roomId, purpose) {
        try {
            const {data} = await axiosInstance.get(`/devices/meetings?roomId=${roomId}&purpose=${purpose}`)

            return data.data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async getMyVideoMeetings() {
        try {
            const {data: {data}} = await axiosInstance.get(`/devices/meetings/new`)
            runInAction(() => {
                this.my_meetings = data
            })
            return data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }
    async getAllVideoMeetings() {
        try {
            const {data: {data}} = await axiosInstance.post(`admin/meetings`,{filter:{}})
            runInAction(() => {
                this.all_meetings = data
            })
        } catch (error) {      

            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async getMeInvitedVideoMeetings() {
        try {
            const {data: {data}} = await axiosInstance.get(`/devices/meetings/invites`)
            this.invite_meetings = data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async declineVideoMeeting(room_id) {
        try {
            const {data} = await axiosInstance.get(`/devices/meetings/decline/${room_id}`)

            return data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async updateVideoRoomById({roomId, info}) {
        try {
            const {data} = await axiosInstance.patch(`/devices/meetings/${roomId}`, info)

            return data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async deleteVideoRoomById({roomId}) {
        try {
            const {data} = await axiosInstance.delete(`/devices/meetings/${roomId}`)

            return data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async getMeetingAllParticiapantsById(room_id) {
        try {
            const {data: {data}} = await axiosInstance.post(`/devices/meetings/${room_id}/participants`)
            return data
        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.response.data.message)

        }

    }

    getFullNameByUserName(username) {
    
        const user = JSON.parse(window.localStorage.getItem("users")).find(el => el.username === username)
        return user?.name ? `${user.name} ${user.last_name}` : username
    }
    async uploadMessages(data) {
        try {
            const {data: {data: response}} = await axiosInstance.post(`/devices/files`, data);

            return response
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async finishedMeeting(room_id) {
        try {
            const {data: {data}} = await axiosInstance.post(`/devices/meetings/close/${room_id}`);

            return data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }

    }

    async declineMeInviteMeeting(room_id) {
        try {
            const {data} = await axiosInstance.get(`/devices/meetings/decline/${room_id}/invites`)

            return data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async deleteMeInviteMeeting(room_id) {
        try {
            const {data} = await axiosInstance.delete(`/devices/meetings/decline/${room_id}/invites`)

            return data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async getMeetingMessagesByRoomId(roomId) {
        try {
            const {data: {data}} = await axiosInstance.get(`/devices/meetings/${roomId}/messages`)

            return data
        } catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.response.data.message)
        }
    }

    async filterMeetingList(info)  {
        try{
            const {data} =  await axiosInstance.post(`/admin/meetings`, {filter: info} )
            this.all_meetings = data.data
        }catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.message)
        }
    }

    async deleteMyOnlineStatus() {
        try{
            await axiosInstance.delete(`/devices/user/call/wait` )
            return {}

        }catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.message)
        }
    }


    changeMeetingsType(id){
        this.all_meetings = this.all_meetings.map(el => el._id === id ? {...el, status: "declined"}: el)
    }

    setParticipantsCount(value){
          this.participants_count = value
    }



    get allMeetings()
    {
        return this.all_meetings;
    }
}

export default new VideoRoom()