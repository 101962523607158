import React, {useContext, useEffect, useState} from "react";
import avatarImage from "../../assets/images/user_avatar.png";
import DeleteMessageRoom from "../../components/DeleteMessageRoom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckCircle, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {ALERT_MESSAGE_TYPE_SUCCESS, BACKEND_URL, MESSAGE_MY} from "../../const/Constants";
import MessageItem from "../../components/MessageItem";
import SOCKET from "../../const/socketNames";
import {useStore} from "../../store";
import {SocketContext} from "../../const/socket";
import {getQueryByParameter} from "../../const/axiosInstance";
import {observer} from "mobx-react-lite";
import FileUpload from "../../components/FileUpload";
import UserChat from "../../states/UserChat";
import AlertComponent from "../../utils/Alert";
import {Menu} from "@headlessui/react";
import User from "../../states/User";
import userAvatar from "../../assets/images/user_avatar.png"
import { langContext } from "../../App";
import { langChat } from "./chatTranslate";
import "./chat.css"
import "../../components/message.css"
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const Chat = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langChat[lang]

        const [message, setMessage] = useState('')
        const {usersStore, userStore, chatStore} = useStore();
        const socket = useContext(SocketContext)
        const [size, setSize] = useState(0)
        const [offset, setOffset] = useState(0)

        const deleteChatRoom = async () => {
            if (window.confirm(showBySelected.tr_deleteMessages)) {
                const result = await UserChat.deleteMessageRoomById()
                if (result.status) {
                    window.location.reload()
                    AlertComponent(ALERT_MESSAGE_TYPE_SUCCESS, result.message)
                }
            }
        }

        useEffect(function () {
            chatStore.setRoomId(getQueryByParameter('roomId'))
            chatStore.setUsername(getQueryByParameter('username'))
            if (chatStore.getRoomId) {
                socket.emit(SOCKET.JOIN_CONNECTED, userStore.username)
                socket.emit(SOCKET.CHAT_PRIVATE_JOIN_ROOM, {roomId: chatStore.getRoomId})
            }
            if (chatStore.username === 'Admin')
                chatStore.getAdminMessages()
            else
                chatStore.getMessages({roomId:chatStore.roomId})

            setTimeout(function () {
                scrollDown()
            }, 200)
            return () => {
                socket.off(SOCKET.CHAT_PRIVATE_JOIN_ROOM)
            }
        }, [])


        function scrollDown() {
            let top = document.getElementById('messages').scrollHeight
            document.getElementById('messages').scrollTop = top
        }
    

        const sendMessage = () => {
            if (!message) {
                return
            }
            // chatStore.addMessage(message, MESSAGE_MY,
            //     new Date().toLocaleDateString('en',
            //         {day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'}))
            socket.emit(SOCKET.CHAT_PRIVATE_GET_MESSAGE, {
                message,
                room_id: chatStore.getRoomId,
                role: userStore.role,
                sender_username: userStore.username,
                receiver_username: chatStore.username,
                size
            })
            setMessage('')

            setTimeout(function () {
                scrollDown()
            }, 0)
        }

        useEffect(() => {
            socket.on(SOCKET.GET_MY_MESSAGE_MYSELF, (message) => {
                chatStore.addMessage(message.message, MESSAGE_MY, new Date().toLocaleDateString('en', {
                    day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'
                }), null, message._id)
                setTimeout(function () {
                    scrollDown()
                }, 0)
            })
            socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
                chatStore.setIsRead()
            })
            return () => {
                socket.off(SOCKET.CHAT_REFRESH_ROOMS)
            }
        }, [])

        useEffect(() => {
            socket.on(SOCKET.CHAT_PRIVATE_SEND_MESSAGE_ADMIN, function (data) {
                chatStore.addMessage(data.message, data.type,
                    new Date().toLocaleDateString('en',
                        {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                            hour: 'numeric',
                            hour12: false,
                            minute: 'numeric'
                        }))
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {name: userStore.username, caller: data.username})


                setTimeout(function () {
                    scrollDown()
                }, 0)
            })
            socket.on(SOCKET.CHAT_PRIVATE_SEND_MESSAGE, function (data) {
                if(data.id !== chatStore.roomId){
                    return
                }

                chatStore.addMessage(data.message, data.type,
                    new Date().toLocaleDateString('en',
                        {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                            hour: 'numeric',
                            hour12: false,
                            minute: 'numeric'
                        }))
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {name: userStore.username, caller: data.username})

                setTimeout(function () {
                    scrollDown()
                }, 0)
            })
            return () => {
                socket.off(SOCKET.CHAT_PRIVATE_SEND_MESSAGE_ADMIN)
                socket.off(SOCKET.CHAT_PRIVATE_GET_MESSAGE)
                socket.off(SOCKET.CHAT_PRIVATE_SEND_MESSAGE)
            }
        }, [socket])

        async function moreData() {
            if (chatStore.username !== "ALL") {
                await chatStore.getMessages({ roomId: chatStore.getRoomId, offset: offset + 10 });
                setOffset(offset => offset + 10)
            } else if (chatStore.user === 'ALL') {
                setOffset(offset => offset + 10)
                await chatStore.getAllMessages(offset, 10)               
    
            }
        }

    const deleteChatMessage = async (id) => {
        if (window.confirm(showBySelected.tr_deleteMessage)) {
            chatStore.deleteMessageById(id).then(res =>  chatStore.getMessages({roomId: chatStore.getRoomId}))
        }
    }

        return (
            <div className="main-newchat-div" style={{"height": window.innerHeight}}>
            <div className="new-page-chat-div" style={{"height": window.innerHeight}}>
                <div className="new-chat-header">
                    <div className="new-chat-user-img-name-div">
                        <div className="">
                            <img
                                src={usersStore.users.find(el => el.username === chatStore.user) ? `${BACKEND_URL}${usersStore.users.find(el => el.username === chatStore.user).image}` : userAvatar}
                                alt="avatar" className="new-chat-user-img"/>
                        </div>
                        <div className="">
                            <div className="">
                                <span className="">{chatStore.user}</span>
                            </div>
                            <span className=""/>
                        </div>
                    </div>
                    <div className=''>
                        <div className="new-chat-icons">
                           <img src="/deleteIcon.png"
                                    onClick={() => deleteChatRoom()}
                                    className="" />
                        </div>
                    </div>
                </div>
                <div className="new-msg-window">
                    <div id='messages'
                         className=''>
                        <button className="new-chat-nots" onClick={() => moreData()}>{showBySelected.tr_seeMore}</button>
                        {chatStore.messages.map((message, index) => {
                            if (message.type === MESSAGE_MY) {
                                return (<div className="my-msg-div" key={index}>
                                    <div className="">
                                        <div className="">
                                            {(chatStore.user === 'ALL') && (
                                                <div className="">
                                                    {message.receiver_username}
                                                </div>)}
                                            <div className="">
                                                <Menu as="div" className="">
                                                    <div className=''>
                                                        <Menu.Button>
                                                            <span
                                                                className="my-msg">
                                                                <MessageItem message={message.message}/>
                                                            </span>
                                                        </Menu.Button>
                                                    </div>
                                                    <Menu.Items
                                                        className="">
                                                        <div className="">
                                                            <Menu.Item>
                                                                <button onClick={() => deleteChatMessage(message._id)}
                                                                                        className="new-msg-delete-btn"><img src="/deleteIcon.png"/>{showBySelected.tr_delete}</button>
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Menu>
                                            </div>
                                            <span
                                                className="time-viewed">{message.created_at} {message.is_viewed ? (
                                                    <img src="/receiptviewed.png" />) : (
                                                        <img src="/receiptnotviewed.png" />)}</span>
                                        </div>
                                    </div>
                                </div>)
                            } else {
                                return (<div className="" key={index}>
                                    <div style={{"width" :"318px"}}>
                                        <div className="">
                                            {(chatStore.user === 'ALL') && (
                                                <div className="">
                                                    {message.username}
                                                </div>)}
                                            <div className="">
                                                <Menu as="div" className="">
                                                    <div className=''>
                                                        <Menu.Button>
                                                <span
                                                    className="msg-me">
                                                        <MessageItem message={message.message}/>
                                                </span>
                                                        </Menu.Button>
                                                    </div>
                                                    <Menu.Items
                                                        className="">
                                                        <div className="">
                                                            <Menu.Item>
                                                               <button onClick={() => deleteChatMessage(message._id)} className="right-move"><img src="/deleteIcon.png"/>{showBySelected.tr_delete}</button>
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Menu>

                                                <span
                                                    className="me-time-viewd">{message.created_at} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                            }
                        })}
                    </div>
                </div>
                <div className="">
                    <div className="new-send-div">
                        <div className="send-msg">
                            <FileUpload setField={setMessage} setSize={setSize} uploadFile={chatStore.uploadMessages}><img src="/Iconfileupload.png" style={{"cursor":"pointer"}}/></FileUpload>
                            <input type="text"
                                // type="text"
                                value={message}
                                onKeyDown={(event) => event.key === 'Enter' && (event.ctrlKey || event.metaKey) ? sendMessage() : ''}
                                onChange={(event) => setMessage(event.target.value.trimStart())}
                                placeholder={showBySelected.tr_correspondence}
                                className="text-send"/>
                        </div>
                            <button
                                type="button"
                                onClick={sendMessage}
                                className="send-btn-private">
                                    <img src="/sendmsg.png"/>                                
                            </button>
                    </div>
                </div>
                </div>
            </div>
        )
    }
)
export default Chat;