import { observer } from "mobx-react-lite"
import { useContext, useEffect, useRef, useState } from "react"
import { ALERT_MESSAGE_TYPE_SUCCESS, BACKEND_URL, MESSAGE_MY } from "../const/Constants"
import { SocketContext } from "../const/socket"
import avatarImage from "../assets/images/user_avatar.png"
import { useStore } from "../store"
import FileUpload from "./FileUpload"
import MessageItem from "./MessageItem"
import SOCKET from "../const/socketNames"
import DeleteMessageRoom from "./DeleteMessageRoom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle, faPaperPlane, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import UserChat from "../states/UserChat";
import AlertComponent from "../utils/Alert";
import { Menu } from "@headlessui/react";
import { langContext } from "../App"
import { langPrivatChat } from "./privateChatTranslate"
import "./message.css"
import userAvatar from "../assets/images/user_avatar.png"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const SingleChat = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langPrivatChat[lang]

    const { userStore, chatStore, usersStore } = useStore();
    const [message, setMessage] = useState('')
    const [offset, setOffset] = useState(0)
    const [allUsers, setAllUsers] = useState({})
    const [size, setSize] = useState(0)
    const ref = useRef(null)
    const socket = useContext(SocketContext)


    function scrollDown() {
        document.getElementById('messages-small').scrollTop = document.getElementById('messages-small').scrollHeight
    }


    async function moreData() {
        if (chatStore.username !== "ALL") {
            await chatStore.getMessages({ roomId: chatStore.getRoomId, offset: offset + 10 });
            setOffset(offset => offset + 10)
        } else if (chatStore.user === 'ALL') {
            setOffset(offset => offset + 10)
            await chatStore.getAllMessages(offset, 10)

        }
    }


    const sendMessage = () => {
        if (!message) {
            return
        }

        socket.emit(SOCKET.CHAT_PRIVATE_GET_MESSAGE, {
            message: message.replace(/\r?\n/g,"\n"),
            room_id: chatStore.getRoomId,
            role: userStore.role,
            sender_username: userStore.username,
            receiver_username: chatStore.username,
            size
        })
        setMessage('')
        setTimeout(function () {
            scrollDown()
        }, 0)

    }

    const chatCloseHandleClick = () => {
        socket.emit(SOCKET.CHAT_CLOSE, { username: userStore.username, guestUsername: chatStore.username })
        chatStore.clearUsername()
        chatStore.clearMessages()
        // window.location.reload()
    }

    const chatHandleClick = (guestUsername) => {
        setOffset(10)
        chatStore.clearUsername()
        chatStore.clearMessages()
        if (guestUsername === 'Admin') {
            socket.emit(SOCKET.CHAT_CREATE, { username: userStore.username, guestUsername: "Admin" })
        } else {
            socket.emit(SOCKET.CHAT_CREATE, { username: userStore.username, guestUsername: guestUsername })
        }
        chatStore.setUsername(guestUsername)
    }

    useEffect(() => {
        socket.on(SOCKET.CHAT_ROOM_INFO, (room) => {
            chatStore.setRoomId(room.id)
            socket.emit(SOCKET.CHAT_PRIVATE_JOIN_ROOM, { roomId: room.id })
            chatStore.getMessages({ roomId: chatStore.getRoomId, offset: 0 });
        })
        socket.on(SOCKET.GET_MY_MESSAGE_MYSELF, (message) => {
            chatStore.addMessage(message.message, MESSAGE_MY, new Date().toLocaleDateString('en', {
                day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'
            }), null, message._id)
            setTimeout(function () {
                scrollDown()
            }, 0)
        })

        return () => {
            socket.off(SOCKET.GET_MY_MESSAGE_MYSELF)
            socket.off(SOCKET.CHAT_ROOM_INFO)
            socket.off(SOCKET.CHAT_PRIVATE_JOIN_ROOM)
        }
    }, [])

    useEffect(() => {
        socket.on(SOCKET.CHAT_PRIVATE_SEND_MESSAGE, function (data) {
            if (chatStore.username === data.username) chatStore.addMessage(
                data.message, data.type, new Date().toLocaleDateString('en', {
                    day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'
                }), null, data.id)
            if (data.role === "Admin") {
                chatStore.setAdminChat(true)
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, { name: userStore.username, caller: data.username })
            } else {
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, { name: userStore.username, caller: data.username })
            }

            setTimeout(function () {
                scrollDown()
            }, 0)
        })
        return () => {
            socket.off(SOCKET.CHAT_PRIVATE_SEND_MESSAGE)
            socket.off(SOCKET.CHAT_PRIVATE_GET_MESSAGE)
        }
    }, [socket])

    useEffect(() => {
        socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
            console.log(data)
            if (chatStore.user === data.guest_username) {
                chatStore.setIsRead()
            }
        })
        return () => {
            socket.off(SOCKET.CHAT_REFRESH_ROOMS)
        }
    }, [])

    useEffect(() => {
        if (chatStore.username === 'ALL') {
            chatStore.getAllMessages(0, 10)
            setOffset(10)
            usersStore.users.forEach(el => setAllUsers(prev => ({ ...prev, [el.username]: el.name || el.last_name ? `${el.name} ${el.last_name}` : el.username })))
            usersStore.unviewedChats = []
        }
        if (chatStore.username === 'Admin') {
            chatStore.getAdminMessages()
        }
        setTimeout(function () {
            scrollDown()
        }, 200)
    }, [chatStore.username])

    const deleteChatRoom = async () => {
        if (window.confirm(showBySelected.tr_deleteMessages)) {
            const result = await UserChat.deleteMessageRoomById()
            if (result.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_SUCCESS, result.message)
                chatCloseHandleClick()
            }
        }
    }

    const deleteChatMessage = async (id) => {
        if (window.confirm(showBySelected.tr_deleteMessage)) {
            chatStore.deleteMessageById(id).then(res => {
                if(chatStore.user === 'ALL'){
                    chatStore.getAllMessages(0, chatStore.messageHistory.length)
                }else {
                    chatStore.getMessages({ roomId: chatStore.getRoomId,offset:0, limit:chatStore.messageHistory.length })
                }
            }
               )
        }
    }




    return (<div ref={ref}
        className="ms-div" >
        <div className="">
            <div className="chat-header">
                <div className="user-img-name-div">

                {(chatStore.user !== 'ALL') && <img
                        src={usersStore.users.find(el => el.username === chatStore.user).image ? `${BACKEND_URL}${usersStore.users.find(el => el.username === chatStore.user).image}` : userAvatar}
                         className="chat-ser-img" style={{"borderRadius":"50%", "marginRight": "0"}}/>}
                
                        <span className="" style={{"marginLeft" : "16px"}}>
                            {(usersStore.users.find(el => el.username === chatStore.user)?.name || usersStore.users.find(el => el.username === chatStore.user)?.last_name || usersStore.users.find(el => el.username === chatStore.user)?.father_name) ? usersStore.users.find(el => el.username === chatStore.user)?.name + ' ' + usersStore.users.find(el => el.username === chatStore.user)?.last_name + ' ' + usersStore.users.find(el => el.username === chatStore.user)?.father_name : chatStore.user === 'ALL' ? showBySelected.tr_myMails : chatStore.user}</span>
                    </div>
                <div className="chat-icons">
                    {(chatStore.user !== 'ALL') && <img src="/deleteIcon.png"  onClick={() => deleteChatRoom()}/>}
                    <img src="/shapeclose.png" onClick={() => chatCloseHandleClick()} />
                </div>
            </div>
            
        </div>
        <div id="messages-small"
            className={chatStore.user === 'ALL' ? "msg-all" : "msg-window"}>
            <div className="chat-nots">
                <button onClick={() => moreData()}>{showBySelected.tr_seeMore}</button>
            </div>
            {chatStore.messages.map((message, index) => {
                if (message.type === MESSAGE_MY) {
                    return (<div className="my-msg-div" key={index}>
                        <div className="">
                            <div className="">
                                {(chatStore.user === 'ALL') && (<div className="fontstyle-ms"
                                    onClick={() => chatHandleClick(message.receiver_username)}>
                                    {allUsers[message.receiver_username]}
                                </div>)}
                                <div className="">
                                    <Menu as="div" className="">
                                        <div className=''>
                                            <Menu.Button>
                                                <span
                                                    className="my-msg">
                                                    <MessageItem message={message.message} />
                                                </span>
                                            </Menu.Button>
                                        </div>
                                        <Menu.Items
                                            className="">
                                            <div className="">
                                                <Menu.Item>
                                                    {({ active }) => (<div onClick={() => deleteChatMessage(message._id)}
                                                        className={classNames(active ? 'bg-gray-100 text-gray-900 msg-delete-btn' : 'text-gray-700 msg-delete-btn', 'block px-4 py-2 text-sm')}><img src="/deleteIcon.png"/>{showBySelected.tr_delete}</div>)}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                </div>
                                <span
                                    className="time-viewed">{message.created_at} {chatStore.user !== 'ALL' &&( message.is_viewed ? (
                                        <img src="/receiptviewed.png" />) : (
                                        <img src="/receiptnotviewed.png" />))}</span>
                            </div>
                        </div>
                    </div>)
                } else {
                    return (<div className="" key={index}>
                        <div style={{"width" :"318px"}}>
                            <div className="">
                                {(chatStore.user === 'ALL') && (<div className="fontstyle-ms m-l-17"
                                    onClick={() => chatHandleClick(message.username)}>
                                    {allUsers[message.username]}
                                </div>)}
                                <div className="">
                                    <Menu as="div" className="">
                                        <div className=''>
                                            <Menu.Button>
                                                <span
                                                    className="msg-me">
                                                    <MessageItem message={message.message} />
                                                </span>
                                            </Menu.Button>
                                        </div>
                                        <Menu.Items
                                            className="">
                                            <div className="">
                                                <Menu.Item>
                                                    {({ active }) => (<div onClick={() => deleteChatMessage(message._id)}
                                                        className={classNames(active ? 'bg-gray-100 text-gray-900 msg-delete-btn' : 'text-gray-700 msg-delete-btn', 'block px-4 py-2 text-sm')} style={{ "margin-left":"16px"}}><img src="/deleteIcon.png"/>{showBySelected.tr_delete}</div>)}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Menu>

                                    <span
                                        className="me-time-viewd">{message.created_at}</span>
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            })}
        </div>
        {chatStore.user !== 'ALL' && (<div className="">
            <div className="send-div">
                <div className="send-msg">
                    <FileUpload setSize={setSize} setField={setMessage} className="upload-img"
                            uploadFile={chatStore.uploadMessages} ><img src="/Iconfileupload.png" style={{"cursor":"pointer"}}/></FileUpload >
                    
                    <input type="text"
                        value={message}
                        onKeyDown={(event) => event.key === 'Enter' && (event.ctrlKey || event.metaKey) ? sendMessage() : ''}
                        onChange={(event) => setMessage(event.target.value.trimStart())}
                        placeholder={showBySelected.tr_correspondence}
                        className="text-send" />
                </div>
                <div
                    className="send-btn-privat">
                    <button
                        type="button"
                        onClick={sendMessage}
                        className="">
                        <img src="/sendmsg.png"/>
                    </button>
                </div>
            </div>
        </div>)}
    </div>)
})


export default SingleChat