export const langMessageList = {
    "arm": {
        tr_messageList: "Նամակների ցուցակ",
        tr_type: "Տեսակ",
        tr_participant: "Մասնակից",
        tr_time: "Ժամ",
        tr_volume: "Չափը",
        tr_message: "Նամակ",
        tr_file: "Ֆայլ"
    },
    "eng": {
        tr_messageList: "Message list",
        tr_type: "Type",
        tr_participant: "Participant",
        tr_time: "Time",
        tr_volume: "Volume",
        tr_message: "Message",
        tr_file: "File"
    },
    "fr": {
        tr_messageList: "Liste de courriers/de messages",
        tr_type: "Type",
        tr_participant: "Participant",
        tr_time: "Heure",
        tr_volume: "Volume de message",
        tr_message: "Courrier/message",
        tr_file: "Fichier"
    },
    "arb": {
        tr_messageList: "قائمة الرسائل",
        tr_type: "نوع",
        tr_participant: "مشارك",
        tr_time: "الساعة",
        tr_volume: "الكمية",
        tr_message: "رسالة",
        tr_file: "ملف"
    }
}