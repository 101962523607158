// import validator from "validator"

import { langValidate } from "./validateTranslate"



export const  passwordValidation = (password)=> {
    // const isValid = validator.isStrongPassword(password, {
        //     minLength: 6
        //   })
    let lang = localStorage.getItem("langFromStorage")
    const selectedLang = langValidate[lang]    

    const isValid = password.length > 7
    
    return {
        isValid,
        message: selectedLang.tr_passLengthValidate
    }
}

export const usernameValidation = (username) => {

    let lang = localStorage.getItem("langFromStorage")
    const selectedLang = langValidate[lang]  
      const isValid =  username.length > 2 && username.length < 18

      return {isValid, message: selectedLang.tr_minAndMaxLength}
}