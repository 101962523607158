export const langDeletePassword = {
    "arm": {
        tr_changeUserPassword: "Փոխել օգտվողի գաղտնաբառը",
        tr_password: "Գաղտնաբառ",
        tr_change: "Փոխել"
    },
    "eng": {
        tr_changeUserPassword: "Change user password",
        tr_password: "Password",
        tr_change: "Change"
    },
    "fr": {
        tr_changeUserPassword: "Changer le mot de passe de l’utilisateur",
        tr_password: "Mot de passe",
        tr_change: "Changer"
    },
    "arb": {
        tr_changeUserPassword: "تغيير كلمة سرّ المستخدم",
        tr_password: "كلمة السرّ",
        tr_change: "تغيير"
    }
}