export const langFilterMessages = {
    "arm": {
        tr_till: "Մինչև",
        tr_files: "Ֆայլեր",
        tr_messages: "Նամակներ",
        tr_search: "Որոնում"
    },
    "eng": {
        tr_till: "Till / up to",
        tr_files: "Files",
        tr_messages: "Messages",
        tr_search: "Search"
    },
    "fr": {
        tr_till: "Jusqu’au",
        tr_files: "Fichiers",
        tr_messages: "Courriers/messages",
        tr_search: "Recherche"
    },
    "arb": {
        tr_till: "لغاية",
        tr_files: "ملفّات",
        tr_messages: "رسائل",
        tr_search: "بحث"
    }
}
